import DocumentIconGoogle from "../../../../assets/images/file-types/google/icon_1_document_x32.png";
import AudioIconGoogle from "../../../../assets/images/file-types/google/icon_1_audio_x32.png";
import TextIconGoogle from "../../../../assets/images/file-types/google/icon_1_text_x32.png";
import FolderIconGoogle from "../../../../assets/images/file-types/google/icon_1_folder_x32.png";
import DrawingIconGoogle from "../../../../assets/images/file-types/google/icon_1_drawing_x32.png";
import FormIconGoogle from "../../../../assets/images/file-types/google/icon_2_form_x32.png";
import FusionIconGoogle from "../../../../assets/images/file-types/google/icon_1_fusion_x32.png";
import PresentationIconGoogle from "../../../../assets/images/file-types/google/icon_1_presentation_x32.png";
import SpreadsheetIconGoogle from "../../../../assets/images/file-types/google/icon_1_spreadsheet_x32.png";
import ImageIconGoogle from "../../../../assets/images/file-types/google/icon_1_image_x32.png";
import MapIconGoogle from "../../../../assets/images/file-types/google/icon_1_map_x32.png";
import WordIconGoogle from "../../../../assets/images/file-types/google/icon_1_word_x32.png";
import ExcelIconGoogle from "../../../../assets/images/file-types/google/icon_1_excel_x32.png";
import PowerpointIconGoogle from "../../../../assets/images/file-types/google/icon_1_powerpoint_x32.png";
import PdfIconGoogle from "../../../../assets/images/file-types/google/icon_1_pdf_x32.png";
import VideoIconGoogle from "../../../../assets/images/file-types/google/icon_1_video_x32.png";

import ZipIconOffice from "../../../../assets/images/file-types/office365/zip.png";
import AudioIconOffice from "../../../../assets/images/file-types/office365/audio.png";
import TxtIconOffice from "../../../../assets/images/file-types/office365/txt.png";
import FolderIconOffice from "../../../../assets/images/file-types/office365/folder.png";
import PhotoIconOffice from "../../../../assets/images/file-types/office365/photo.png";
import LinkIconOffice from "../../../../assets/images/file-types/office365/link.png";
import DocxIconOffice from "../../../../assets/images/file-types/office365/docx.png";
import XlsxIconOffice from "../../../../assets/images/file-types/office365/xlsx.png";
import OneIconOffice from "../../../../assets/images/file-types/office365/one.png";
import PptxIconOffice from "../../../../assets/images/file-types/office365/pptx.png";
import PdfIconOffice from "../../../../assets/images/file-types/office365/pdf.png";
import VideoIconOffice from "../../../../assets/images/file-types/office365/video.png";

const viewerType_GOOGLE = 'google';
const viewerType_GOOGLEVIEWER = 'googleviewer';
const viewerType_HTML5AUDIO = 'html5audio';
const viewerType_HTML5VIDEO = 'html5video';
const viewerType_IFRAME = 'iframe';
const viewerType_IMAGE = 'image';
const viewerType_LINK = 'link';
const viewerType_OFFICE365 = 'office365';
const viewerType_PDFJS = 'pdfjs';

const fileType_ARCHIVE = "zip";
const fileType_AUDIO = "audio";
const fileType_FILE = "txt";
const fileType_FOLDER = "folder";
const fileType_GOOGLEDOCUMENT = "gdocument";
const fileType_GOOGLEDRAWING = "gdrawing";
const fileType_GOOGLEFORM = "gform";
const fileType_GOOGLEFUSIONTABLE = "gfusion";
const fileType_GOOGLEPRESENTATION = "gpresentation";
const fileType_GOOGLESPREADSHEET = "gspreadsheet";
const fileType_HTML = "html";
const fileType_IMAGE = "image";
const fileType_LINK = "link";
const fileType_MAP = "map";
const fileType_MSWORD = "docx";
const fileType_MSEXCEL = "xlsx";
const fileType_MSONENOTE = "one";
const fileType_MSPOWERPOINT = "pptx";
const fileType_PDF = "pdf";
const fileType_VIDEO = "video";

const mimeTypeMapping = {
    'application/gzip': fileType_ARCHIVE,
    'application/msword': fileType_MSWORD,
    'application/onenote': fileType_MSONENOTE,
    'application/pdf': fileType_PDF,
    'application/postscript': fileType_PDF,
    'application/vnd.google-apps.audio': fileType_AUDIO,
    'application/vnd.google-apps.document': fileType_GOOGLEDOCUMENT,
    'application/vnd.google-apps.drawing': fileType_GOOGLEDRAWING,
    'application/vnd.google-apps.file': fileType_FILE,
    'application/vnd.google-apps.folder': fileType_FOLDER,
    'application/vnd.google-apps.form': fileType_GOOGLEFORM,
    'application/vnd.google-apps.fusiontable': fileType_GOOGLEFUSIONTABLE,
    'application/vnd.google-apps.map': fileType_MAP,
    'application/vnd.google-apps.photo': fileType_IMAGE,
    'application/vnd.google-apps.presentation': fileType_GOOGLEPRESENTATION,
    'application/vnd.google-apps.spreadsheet': fileType_GOOGLESPREADSHEET,
    'application/vnd.google-apps.video': fileType_VIDEO,
    'application/vnd.ms-excel': fileType_MSEXCEL,
    'application/vnd.ms-powerpoint': fileType_MSPOWERPOINT,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': fileType_MSPOWERPOINT,
    'application/vnd.openxmlformats-officedocument.presentationml.slide': fileType_MSPOWERPOINT,
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': fileType_MSPOWERPOINT,
    'application/vnd.openxmlformats-officedocument.presentationml.template': fileType_MSPOWERPOINT,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': fileType_MSEXCEL,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': fileType_MSEXCEL,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': fileType_MSWORD,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': fileType_MSWORD,
    'application/x-7z-compressed': fileType_ARCHIVE,
    'text/html': fileType_HTML,
    'image/jpeg': fileType_IMAGE,
    'image/jpg': fileType_IMAGE,
    'image/png': fileType_IMAGE,
};

const fileTypeIcons = {
    google: {},
    office365: {},
};

let iconSet = "google";
fileTypeIcons[iconSet][fileType_AUDIO] = AudioIconGoogle;
fileTypeIcons[iconSet][fileType_FILE] = TextIconGoogle;
fileTypeIcons[iconSet][fileType_FOLDER] = FolderIconGoogle;
fileTypeIcons[iconSet][fileType_GOOGLEDOCUMENT] = DocumentIconGoogle;
fileTypeIcons[iconSet][fileType_GOOGLEDRAWING] = DrawingIconGoogle;
fileTypeIcons[iconSet][fileType_GOOGLEFORM] = FormIconGoogle;
fileTypeIcons[iconSet][fileType_GOOGLEFUSIONTABLE] = FusionIconGoogle;
fileTypeIcons[iconSet][fileType_GOOGLEPRESENTATION] = PresentationIconGoogle;
fileTypeIcons[iconSet][fileType_GOOGLESPREADSHEET] = SpreadsheetIconGoogle;
fileTypeIcons[iconSet][fileType_IMAGE] = ImageIconGoogle;
fileTypeIcons[iconSet][fileType_MAP] = MapIconGoogle;
fileTypeIcons[iconSet][fileType_MSWORD] = WordIconGoogle;
fileTypeIcons[iconSet][fileType_MSEXCEL] = ExcelIconGoogle;
fileTypeIcons[iconSet][fileType_MSPOWERPOINT] = PowerpointIconGoogle;
fileTypeIcons[iconSet][fileType_PDF] = PdfIconGoogle;
fileTypeIcons[iconSet][fileType_VIDEO] = VideoIconGoogle;

iconSet = "office365";
fileTypeIcons[iconSet][fileType_ARCHIVE] = ZipIconOffice;
fileTypeIcons[iconSet][fileType_AUDIO] = AudioIconOffice;
fileTypeIcons[iconSet][fileType_FILE] = TxtIconOffice;
fileTypeIcons[iconSet][fileType_FOLDER] = FolderIconOffice;
fileTypeIcons[iconSet][fileType_IMAGE] = PhotoIconOffice;
fileTypeIcons[iconSet][fileType_LINK] = LinkIconOffice;
fileTypeIcons[iconSet][fileType_MSWORD] = DocxIconOffice;
fileTypeIcons[iconSet][fileType_MSEXCEL] = XlsxIconOffice;
fileTypeIcons[iconSet][fileType_MSONENOTE] = OneIconOffice;
fileTypeIcons[iconSet][fileType_MSPOWERPOINT] = PptxIconOffice;
fileTypeIcons[iconSet][fileType_PDF] = PdfIconOffice;
fileTypeIcons[iconSet][fileType_VIDEO] = VideoIconOffice;

export function getIconByMimeType(iconSet, mimeType) {
    let icon = fileTypeIcons[iconSet][mimeTypeMapping[mimeType]];
    if (!icon && iconSet === 'google') {
        icon = fileTypeIcons[iconSet]['txt'];
    }
    return icon;
}

export function getIconSet(sourceType) {
    if (/drive/.test(sourceType)) {
        return 'google';
    } else if (/office365|o365/.test(sourceType)) {
        return 'office365'
    }
}

export function getIconSetFromUrl(url) {
    if (
        /(onedrive\.live\.com)|(1drv\.ms)|(sharepoint\.com)/i.exec(url)
    ) {
        return "office365";
    }
    return "google";
}

export function getFolderIcon(sourceType) {
    const iconSet = getIconSet(sourceType);
    return fileTypeIcons[iconSet][fileType_FOLDER];
}

export function getLegacyFileIcon(file) {
    const iconSet = getIconSet(file?.viewerType) || "google";
    let icon;
    if (mimeTypeMapping[file?.mimeType]) {
        icon = fileTypeIcons[iconSet][mimeTypeMapping[file.mimeType]];
    }
    if (!icon && fileTypeIcons[iconSet][file?.type]) {
        icon = fileTypeIcons[iconSet][file.type];
    }
    if (!icon) {
        icon = fileTypeIcons[iconSet][fileType_FILE];
    }
    return icon;
}

export function getContentItemIcon(fileOrFolderContentItem) {
    const iconSet = getIconSet(fileOrFolderContentItem?.type);
    if (fileOrFolderContentItem.is_folder) {
        return fileTypeIcons[iconSet][fileType_FOLDER];
    } else {
        let icon;
        if (fileOrFolderContentItem.json_data.file.mime_type) {
            icon = fileTypeIcons[iconSet][mimeTypeMapping[fileOrFolderContentItem.json_data.file.mime_type]];
        }
        if (!icon) {
            const typeAsList = fileOrFolderContentItem.type.split(".");
            const type = typeAsList[typeAsList.length - 1];
            icon = fileTypeIcons[iconSet][type];
        }
        if (!icon) {
            icon = fileTypeIcons[iconSet][fileType_FILE];
        }
        return icon;
    }
}