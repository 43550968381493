import {makeObservable, observable} from 'mobx';
import BlockObject from "./BlockObject";

export default class TwitterBlockObject extends BlockObject implements ITwitterBlockObj {

    handle: string;
    handleUrl: string;
    tweets: [];

    constructor(blockType: OrganizationType, initialBlockObj: ITwitterBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            handle: observable,
            handleUrl: observable,
            tweets: observable,
        })
    }
}
