import BlockObject from './BlockObject';
import {makeObservable, observable} from "mobx";

export default class HtmlBlockObject extends BlockObject {
    code: string;

    constructor(blockType: OrganizationType, initialBlockObj: IHtmlBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            code: observable,
        })
    }
}
