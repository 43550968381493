import BlockObject from "./BlockObject";

export default class ReuseBlockObject extends BlockObject implements IReuseBlockObj {

    blockComponent: {
        reusedBlockComponent: {
            settings: {
                display: {
                    childLocked: boolean;
                    locked?: boolean;
                    lockedPosX?: number;
                    lockedPosY?: number;
                    schools?: any
                }
            }
        };
        reusedBlockModel: OrganizationTypeOrganization;
        reusedBlockId: string; blockType?: OrganizationType
    };
    reusedBlockModel: OrganizationTypeOrganization;

    constructor(blockType: OrganizationType, initialBlockObj: IReuseBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        this.setAttributes({
            blockModel: this.blockComponent.reusedBlockModel,
            blockType: this.blockComponent.reusedBlockModel.type,
        })
        const isLockedByParent = this.blockComponent.reusedBlockComponent.settings.display.childLocked;
        if (isLockedByParent) {
            const reusedBlockPosX = this.blockComponent.reusedBlockComponent.settings.display.lockedPosX;
            const reusedBlockPosY = this.blockComponent.reusedBlockComponent.settings.display.lockedPosY;
            const positionLocked = this.blockComponent.reusedBlockComponent.settings.display.locked;
            this.setAttributes({
                posX: (reusedBlockPosX ? reusedBlockPosX : this.posX),
                posY: (reusedBlockPosY ? reusedBlockPosY : this.posY),
                positionLocked: (positionLocked ? positionLocked : this.positionLocked),
            })
        }
    }
}
