import {ConnectionFactory, DefaultController} from "../../../backends/YiiRoutes";
import NotificationManager from '../../notifications/NotificationManager';
import EventBlockObject from "./BlockObjects/EventBlockObject";
import FacebookBlockObject from "./BlockObjects/FacebookBlockObject";
import GenericBlockObject from "./BlockObjects/GenericBlockObject";
import GroupBlockObject from "./BlockObjects/GroupBlockObject";
import HtmlBlockObject from "./BlockObjects/HTMLBlockObject";
import InstagramBlockObject from "./BlockObjects/InstagramBlockObject";
import MessageBlockObject from "./BlockObjects/MessageBlockObject";
import QuickLinkBlockObject from "./BlockObjects/QuickLinkBlockObject";
import NewsBlockObject from "./BlockObjects/NewsBlockObject";
import OrganizationBlockObject from "./BlockObjects/OrganizationBlockObject";
import PinterestBlockObject from "./BlockObjects/PinterestBlockObject";
import PollBlockObject from "./BlockObjects/PollBlockObject";
import PageBlockObject from "./BlockObjects/PageBlockObject";
import ReuseBlockObject from "./BlockObjects/ReuseBlockObject";
import SchoolFeedFullPageBlockObject from "./BlockObjects/SchoolFeedFullPageBlockObject";
import SharedFilesBlockObject from "./BlockObjects/SharedFilesBlockObject";
import StaffBlockObject from "./BlockObjects/StaffBlockObject";
import TwitterBlockObject from "./BlockObjects/TwitterBlockObject";
import VideoBlockObject from "./BlockObjects/VideoBlock/VideoBlockObject";
import WeatherBlockObject from "./BlockObjects/WeatherBlockObject";
import WatsonApi from "../../../backends/WatsonApi";
import {getIconSetFromUrl, getLegacyFileIcon} from "../TerracedGridTheme/FilesFoldersBlock/fileUtilities";
import {getCdnUrl} from "../../../utils/SchoolBlocksUtilities";
import PersonBlockObject from "./BlockObjects/PersonBlockObject";
import moment from "moment/moment";

export function blockObjectFactory(blockType: IBlockType, initialProps?: AnyBlockObj): IBlockObj | IPersonBlock {
    switch (blockType) {
        case "eventlist": return new EventBlockObject(blockType, initialProps as IEventBlockObj);
        case "group":
        case "department":
        case "section":
        case "team":
            return new GroupBlockObject(blockType, initialProps as IGroupBlockObj);
        case "facebook": return new FacebookBlockObject(blockType, initialProps as IFacebookBlockObj);
        case "html": return new HtmlBlockObject(blockType, initialProps as IHtmlBlockObj);
        case "instagram": return new InstagramBlockObject(blockType, initialProps as IInstagramBlockObj);
        case "message": return new MessageBlockObject(blockType, initialProps as IMessageBlockObj);
        case "news": return new NewsBlockObject(blockType, initialProps as INewsBlockObj);
        case "school":
        case "district":
        case "organization":
        case "classroom":
            return new OrganizationBlockObject(blockType, initialProps as IBlockObj);
        case "page": return new PageBlockObject(blockType, initialProps as IPageBlockObj);
        case "person": return new PersonBlockObject(blockType, initialProps as IPersonBlock);
        case "pinterest": return new PinterestBlockObject(blockType, initialProps as IPinterestBlockObj);
        case "poll": return new PollBlockObject(blockType, initialProps as IPollBlockObj);
        case "quicklink": return new QuickLinkBlockObject(blockType, initialProps as IQuicklinkBlockObj);
        case "reuse": return new ReuseBlockObject(blockType, initialProps as IReuseBlockObj);
        case "content_item": return new SchoolFeedFullPageBlockObject(blockType, initialProps as IBlockObj);
        case "sharedfiles": return new SharedFilesBlockObject(blockType, initialProps as ISharedFilesBlockObj);
        case "staff": return new StaffBlockObject(blockType, initialProps as IStaffBlockObj);
        case "twitter": return new TwitterBlockObject(blockType, initialProps as ITwitterBlockObj);
        case "weather": return new WeatherBlockObject(blockType, initialProps as IWeatherBlockObj);
        case "youtube":
        case "video": return new VideoBlockObject(blockType, initialProps as IVideoBlockObj);
        default:
            return new GenericBlockObject(blockType, initialProps as IBlockObj);
    }
}

function reactifyCssProperty(property) {
    // check for properties like "margin-top" and replace with "marginTop"
    const shouldFormatRegex = /(-)(\S){1}/;
    if (shouldFormatRegex.test(property)) {
        const match = property.match(shouldFormatRegex);
        return property.replace(shouldFormatRegex, match[2].toUpperCase());
    }
    return property;
}

export function cssStringToObject(str) {
    if (typeof str !== "string") return str;
    return str
        .split("; ")
        .map(s => s.replace(/[;\s]*/g, "").split(":"))
        .reduce((acc, curr) => Object.assign(acc, {[reactifyCssProperty(curr[0])]: curr[1]}), {});
}

const defaultByCategory = {
    "root.6b4ae6c8b4664ab184d64b23c2951ee8": require("../../../assets/images/defaultByCategory/root.6b4ae6c8b4664ab184d64b23c2951ee8-ccahPlaceHolder.jpg"),
    "root.240b6abc2c7f4b80a415c9e5e8e81251": require("../../../assets/images/defaultByCategory/root.240b6abc2c7f4b80a415c9e5e8e81251-ccahPlaceHolder.jpg"),
    "root.fd9883dfb4d046d0bbf54e8034392c56": require("../../../assets/images/defaultByCategory/root.fd9883dfb4d046d0bbf54e8034392c56-ccahPlaceHolder.jpg"),
};

function getPathAsList(path) {
    const pathComponents = path.split(".");
    return pathComponents.reduce((acc, curr) => {
        if (acc.length === 0) {
            acc.push(curr);
        } else {
            acc.push(`${acc[acc.length - 1]}.${curr}`)
        }
        return acc;
    }, [])
}

export function getDefaultOrganizationUrl(organizationModel) {
    let pictureUrl = "";
    if (organizationModel.all_categories && organizationModel.all_categories.length > 0) {
        const primaryCategory = organizationModel.all_categories[0];
        const paths = getPathAsList(primaryCategory.path).reverse();
        for (let i = 0; i < paths.length; i++) {
            if (defaultByCategory[paths[i]]) {
                pictureUrl = defaultByCategory[paths[i]];
                break;
            }
        }
    }
    return pictureUrl;
}

export function getLogoUrl(organizationModel) {
    if (organizationModel.json_data?.settings?.logo) {
        return getCdnUrl(organizationModel.json_data.settings.logo);
    }
    return null;
}

export function getPictureUrl(organizationModel) {
    if (organizationModel.json_data?.settings?.picture) {
        return getCdnUrl(organizationModel.json_data.settings.picture);
    } else {
        switch (organizationModel.type) {
            case "district":
            case "school":
                return require("../../../assets/images/school_photo.png");
            default:
                return getDefaultOrganizationUrl(organizationModel);
        }
    }
}

export function getAvatarUrl(personModel) {
    if (personModel.avatarUrl) {
        return personModel.avatarUrl;
    }
    return require("../../../assets/images/person-placeholder.jpg");
}

export function getLastLogin(personModel: IUser) {
    if (personModel.lastvisit_at) {
        // TODO once Watson side goes out this can be removed (along with type definition)
        return moment(personModel.lastvisit_at).fromNow();
    } else if (personModel.last_login) {
        return moment(personModel.last_login).fromNow();
    }
    return null;
}

export function getBlockIconByType(blockType) {
    switch (blockType) {
        case "social":
        case "youtube":
        case "facebook":
        case "twitter":
        case "pinterest":
        case "instagram":
            return require("../../../assets/images/menu_icon_social.png");
        case "sharedfiles":
            return require("../../../assets/images/menu_icon_files.png");
        case "schooltube":
            return require("../../../assets/images/schooltube_icon.png");
        case "group":
        case "section":
        case "department":
            return require("../../../assets/images/menu_icon_section.png");
        default:
            return ""
    }
}

export function getLogoHtml(block) {
    let iconHtml: string | JSX.Element = "";

    const branding = block.branding;
    const title = block.title;

    if (branding.logo?.fontawesome) {
        iconHtml = <span className={branding.logo.fontawesome} role="img" aria-label={branding.ariaLabel}/>
    } else if (block.model.type === "sharedfiles") {
        const fakeBlockObject = {
            type: block.component.settings.fileType,
            viewerType: getIconSetFromUrl(block.component.settings.url),
        }
        const imageUrl = getLegacyFileIcon(fakeBlockObject);
        iconHtml = <img className={"sb-block-logo"} style={{marginTop: "1em", height: "2em", width: "auto"}} src={imageUrl} alt={title}/>
    } else {
        const iconCss = branding.logo?.iconCss ? cssStringToObject(branding.logo.iconCss) : {height: "70%"};
        iconHtml = <img className={"sb-block-logo"} style={iconCss} src={getBlockIconByType(block.model.type)} alt={title}/>
    }

    return iconHtml;
}

async function updateGrid(gridID, sortedBlockData) {
    const client = await WatsonApi();
    const result = await client.apis.organizations.organizations_blocks_update_grid({
        organization_pk: gridID,
        data: sortedBlockData,
    });
    return JSON.parse(result.data);
}

export function sb_updateGrid(gridStore) {
    updateGrid(gridStore.id, gridStore.sortedBlockState).then(function (res) {
        if (res.success === true) {
            NotificationManager.success("Updated grid layout saved.");
        } else {
            NotificationManager.error("Failed to update grid layout. Please try again.");
        }
    });
}

/**
 * @param {string} locale
 * @param {string} orgID
 * @param {string} blockID
 * @param {string} blockType
 */
export function getEditBlockForm(locale, orgID, blockID, blockType) {
    return ConnectionFactory(
        orgID,
        DefaultController.getEditBlock(locale, blockType),
        {
            data: {
                blockID: blockID,
            },
        }
    );
}

export function getNewBlockForm(locale, blockType, gridID) {
    return ConnectionFactory(
        gridID,
        DefaultController.getEditBlock(locale, blockType),
        {
            data: {
                gridID: gridID,
            },
        }
    );
}

export async function uploadBlockImage(organization, file, blockID) {
    if (!blockID) {
        blockID = "undefined";
    }
    const client = await WatsonApi();
    const response = await client.apis.organizations.organizations_blocks_upload_image({
        organization_pk: organization.id,
        id: blockID,
        file,
    });
    return JSON.parse(response.data);
}

export async function updateBlockHeaderTitle(blockId, parentId, blockType, title) {
    const client = await WatsonApi();
    const response = await client.apis.organizations.organizations_blocks_partial_update({
        organization_pk: parentId,
        id: blockId,
        data: {
            type: blockType,
            title: title,
        },
    })
}
