import {makeObservable, observable} from 'mobx';
import BlockObject from "./BlockObject";

export default class PageBlockObject extends BlockObject implements IPageBlockObj {

    constructor(blockType: OrganizationType, initialBlockObj: IPageBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            modified: observable,
            pageContent: observable,
            pageTitle: observable,
            pageUrl: observable,
        })
    }

    modified: string;
    pageContent: string;
    pageTitle: string;
    pageUrl: string;
}
