import Swagger from 'swagger-client';

import {i18nStore} from "../stores/StoreLoader";
import {ExecutionEnvironment} from "../stores/InterfaceStore";
import {getConfig} from "../_configs/AppConfig";
import {getCookieFromString} from "../utils/StringUtilities";

const {publicRuntimeConfig} = getConfig();

function createSingleton(swaggerAuthorization): Swagger {
    const swag = {
        url: publicRuntimeConfig.API_SPEC_URL,
        usePromise: true,
        debug: false,
        requestInterceptor: req => {
            if (req.url === publicRuntimeConfig.API_SPEC_URL) {
                // default Accept value in swagger-client 3.x is "application/json, "application/yaml", but Watson uses "application/openapi+json"
                req.headers["Accept"] = ["application/openapi+json"];
            }
        },
    }
    if (swaggerAuthorization) {
        // @ts-ignore
        swag.authorizations = {
            WatsonAuthentication: swaggerAuthorization,
        }
    }
    return new Swagger(swag);
}

let swaggerSingleton: Swagger | null = null;

export default async function WatsonApi(token = null) {
    if (ExecutionEnvironment.canUseDOM) {
        token = getCookieFromString("token", document.cookie);
    }

    // always create a new singleton for server-side calls, or credentials will be shared across clients!
    if (!swaggerSingleton || !ExecutionEnvironment.canUseDOM) {
        swaggerSingleton = createSingleton(token);
    }

    const client = await swaggerSingleton;

    if (!client.authorizations) {
        client.authorizations = {}
    }
    if (token) {
        client.authorizations["WatsonAuthentication"] = token
    }
    client.authorizations["accept-language"] = i18nStore.currentOrgSupportedLocale
    client.authorizations["AppContext"] = process.env.NEXT_PUBLIC_APP_CTX

    return client;
}
