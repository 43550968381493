import SoprisApps from '@soprisapps/components/pages/_app';

// vendor bundle
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/v4-shims.css";
import "../src/scss/fonts.scss";
import "@yaireo/tagify/dist/tagify.css";

import "@soprisapps/components/assets/flaticon/flaticon.css";
import "@soprisapps/components/assets/sbicon/sbicon.css";
import "react-metismenu/dist/react-metismenu-standart.min.css";
import 'react-phone-number-input/style.css';
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";
import "react-datetime/css/react-datetime.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "cropperjs/dist/cropper.css";

// blocks bundle
import '../src/css/instagram.css';
import '../src/css/poll.css';
import '../src/css/googlefileviewer.css';
import '../src/css/ui.fancytree-win7.css';

// react
import "jsdifflib/index.css";
import "@soprisapps/components/pages/PageBlockPage/styles/scopedContentBuilderEditor.scss";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

// main bundle
import '../src/scss/style.scss';
import '../src/scss/_reset.scss';
import '../src/scss/_organization.scss';
import '../src/scss/_notification-styles.scss';
import '../src/scss/_floating-blocks-theme.scss';
import '../src/scss/_minimal-theme.scss';

export default SoprisApps;
