import {action, autorun, makeAutoObservable, set, remove, observable, get, has} from "mobx";

import WatsonApi from "../backends/WatsonApi";
import deepmerge from 'deepmerge'
import {convertFlatToTree} from "../utils/DataUtilities";
import {defaultJsonData} from "../utils/Settings";
import {isJson} from "../components/admin/spinup/utilities";
import {ExecutionEnvironment} from "./InterfaceStore";
import {getConfig} from "../_configs/AppConfig";

const {publicRuntimeConfig} = getConfig();

export function mergeBlockItems(oldItems, newItems) {
    return [...oldItems, ...newItems].reduce((acc, current) => {
        if (!acc.find(c => c.id === current.id)) {
            acc.push(current);
        }
        return acc;
    }, [])
}

export const blockLookups = {
    news: ['is_news'],
    events: ['is_event'],
    media: ['is_media'],
    files: ['is_file', 'is_folder'],
};

export enum fetchState {
    "PENDING SCHEMA",
    "PENDING RESPONSE",
    "SUCCESS",
    "FAILURE",
}

export class FetchState {
    public value;
    public message;

    constructor({value, message}: {value: fetchState, message: string}) {
        this.value = value;
        this.message = message;
        makeAutoObservable(this);
    }
    set({value, message}: {value: fetchState, message: string}) {
        this.value = value;
        this.message = message;
    }
}

export default class OrganizationStore {
    private userId = null;

    constructor(userStore) {
        if (ExecutionEnvironment.canUseDOM) { // don't leak memory!
            autorun(() => {
                this.setUserId(userStore.id)
            });
        }

        makeAutoObservable(this, {
            updateThemeSettings: action,
        });
    }

    setUserId = id => {
        this.userId = id;
    };

    organization: OrganizationTypeOrganization | Partial<OrganizationTypeOrganization> = {}; // title organization
    currentOrganization: OrganizationTypeOrganization | Partial<OrganizationTypeOrganization> = {};
    _navigation: Navigation[] = [];

    content_sources: ContentSource[] = [];
    hostname = null;

    customNavigationIsDisabled = true;

    userSites = [];

    get navigation() {
        return this._navigation;
    }

    set navigation(flatArray) {
        this._navigation = flatArray;
    }

    get navigationTree() {
        return convertFlatToTree(this._navigation);
    }

    get isMyCleverFeed() {
        return this.currentOrganization.id === publicRuntimeConfig.MY_CLEVERFEED_ORG_ID;
    }

    setCurrentOrganization = (org: OrganizationTypeOrganization) => {
        this.currentOrganization = org;
    }

    updateThemeSettings = (obj, newThemeID = false) => {
        if (newThemeID && this.organization.json_data) {
            this.organization.json_data.settings.appearance.themeID = String(newThemeID);
        }
        const themeID = this.organization.json_data?.settings.appearance.themeID;
        if (themeID) {
            for (const k in obj) {
                if (obj.hasOwnProperty(k) && this.organization.json_data) {
                    this.organization.json_data.settings.appearance.themes[themeID][k] = obj[k];
                }
            }
        }
    };

    fetchStates = observable.map();

    removeFetchState(requestId) {
        if (requestId) {
            remove(this.fetchStates, requestId);
        }
    }

    updateFetchState(requestId, state) {
        if (requestId) {
            if (has(this.fetchStates, requestId)) {
                get(this.fetchStates, requestId).set(state);
            } else {
                set(this.fetchStates, requestId, new FetchState(state));
            }
        }
    }

    async fetchClient (tag, fn, args = {}, options: {
        requestId?: string,
        requestSuccessMessage?: string,
        organization_id?: OrganizationTypeOrganization["id"],
        user_id?: string,
        spinup?: boolean,
    } = {}) {
        const {
            requestId = null, // ID for tracking progress of request
            requestSuccessMessage = "Beep, boop... all done, your site has been updated!",
            organization_id = this.currentOrganization?.id || this.organization.id,
            user_id = this.userId,
            spinup = false,
        } = options;

        try {
            this.updateFetchState(requestId, {value: fetchState["PENDING SCHEMA"]});
            const client = await WatsonApi();
            this.updateFetchState(requestId, {value: fetchState["PENDING RESPONSE"]});
            const res = await client.apis[tag][fn](args);

            this.updateFetchState(requestId, {value: fetchState.SUCCESS, message: requestSuccessMessage});
            return res;
        } catch (error) {
            let message = "";
            if (typeof error === 'string') {
                message = `Oops! ${error}`;
            } else if (isJson(error)) {
                message = `Oops! ${JSON.parse(error).statusText.detail}`
            } else if (isJson(error?.data)) {
                message = JSON.parse(error.data).detail;
            }
            this.updateFetchState(requestId, {value: fetchState.FAILURE, message});
        } finally {
            this.removeFetchState(requestId);
        }
    }

    buildOrganization(newOrg: OrganizationTypeOrganization) {
        let titleOrg = newOrg;

        if (titleOrg.categories && titleOrg.categories.length > 0) {
            newOrg.vertical = titleOrg.categories[0].path;
        } else {
            newOrg.vertical = 'generic';
        }

        const defaultSettings = defaultJsonData(newOrg.vertical);
        titleOrg.json_data = deepmerge(defaultSettings, titleOrg.json_data);

        if (titleOrg.navigation && Array.isArray(titleOrg.navigation)) {
            // this map can be removed once we're totally on Watson, but for now there are some discrepancies between
            // the model field names of Yii and Watson, so this is here to keep things consistent
            this.navigation = titleOrg.navigation;
        }

        // hero should be an array, let's make it backwards compatible in case any objects or strings still exist
        if (titleOrg.json_data.settings.hero && !Array.isArray(titleOrg.json_data.settings.hero)) {
            titleOrg.json_data.settings.hero = [titleOrg.json_data.settings.hero]
        }

        this.organization = titleOrg;
    }

    getContentSources = function* (organization_id = this.organization.id) {
        try {
            const obj = yield this.fetchClient("organizations", "organizations_content_sources_list", {
                "organization_pk": organization_id,
            });
            if (obj?.data) {
                const data = JSON.parse(obj.data);
                return this.content_sources = data.data;
            }
        } catch (error) {
            console.debug(error);
        }
    }

    updateSettings = function* (update, showNotification = true) {
        try {
            const result = yield this.fetchClient("settings", "settings_update", {
                "id": this.organization.id,
                "data": {...update},
            }, {
                requestId: showNotification ? "updateSettings": null,
            });
            if (result?.data) {
                const settings = {
                    ...this.organization.json_data.settings,
                    ...JSON.parse(result.data).settings,
                };
                this.organization.json_data.settings = settings;
                return settings;
            }
        } catch (error) {
            console.debug(error);
        }
    }

    imageUpload = function* (
        attribute: string,
        file: any,
        imageName: "logo" | "hero" | "picture" | "icon" | "favicon" | "backgroundImage"
    ) {
        const client = yield WatsonApi();
        const obj = yield client.apis.organizations.organizations_file_upload_update({
            organization_pk: this.currentOrganization.id,
            name: imageName,
            file: file,
            mime_type: file.type,
            attribute,
        })
        if (obj?.data) {
            const media = JSON.parse(obj.data);

            if (attribute === 'hero') {
                this.organization.json_data.settings.hero.splice(0, 0, media)
            }

            return media;
        }
    }

    pollSource = async id => {
        try {
            const obj = await this.fetchClient('content-sources', "content_sources_states_list", {
                contentsource_pk: id,
            });
            if (obj?.data) {
                return JSON.parse(obj.data);
            }
        } catch (error) {
            console.debug(error);
        }
    };
}
