import {ExecutionEnvironment} from "../stores/InterfaceStore";

export function getCssVar(val) {
    if (ExecutionEnvironment.canUseDOM) {
        return document.documentElement.style.getPropertyValue(val);
    }
    return null;
}

export function checkContrast(foregroundColor: string, backgroundColor: string): boolean {
    // remove # from hex color string
    const L1 = getL(foregroundColor.replace("#", "")),
        L2 = getL(backgroundColor.replace("#", ""));

    if (L1 !== false && L2 !== false) {
        const ratio = (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
        if (ratio >= 4.5) {
            return true;
        }
    }
    // returns true if contrast is okay, returns false if not
    return false;
}

export function getL(color) {
    var R,
        G,
        B,
        L,
        update = false;

    if (color.length === 3) {
        R = getsRGB(color.substring(0, 1) + color.substring(0, 1));
        G = getsRGB(color.substring(1, 2) + color.substring(1, 2));
        B = getsRGB(color.substring(2, 3) + color.substring(2, 3));
        update = true;
    } else if (color.length === 6) {
        R = getsRGB(color.substring(0, 2));
        G = getsRGB(color.substring(2, 4));
        B = getsRGB(color.substring(4, 6));
        update = true;
    }

    if (update === true) {
        L = (0.2126 * R + 0.7152 * G + 0.0722 * B);
        return L;
    }

    return false;
}

export function getsRGB(color) {
    color = getRGB(color);
    if (color !== false) {
        color = color / 255;
        color = (color <= 0.03928) ? color / 12.92 : Math.pow(((color + 0.055) / 1.055), 2.4);
        return color;
    } else {
        return false;
    }
}

export function getRGB(color) {
    try {
        color = parseInt(color, 16);
    } catch (err) {
        color = false;
    }
    return color;
}

export function color_lightness(color) {
    let R, G, B, lightness;
    color = color.replace("#", "");

    if (color.length === 3) {
        R = color.substring(0, 1) + color.substring(0, 1);
        G = color.substring(1, 2) + color.substring(1, 2);
        B = color.substring(2, 3) + color.substring(2, 3);
    } else if (color.length === 6) {
        R = color.substring(0, 2);
        G = color.substring(2, 4);
        B = color.substring(4, 6);
    }

    R = getRGB(R);
    G = getRGB(G);
    B = getRGB(B);

    let HSL = RGBtoHSL(R, G, B);
    lightness = HSL[2];

    return lightness;
}

export function changehue(color, dir) {
    color = color.replace("#", "");
    let lightness,
        update = color.length === 3 || color.length === 6;

    lightness = color_lightness(color);

    if (update) {
        lightness = (dir === "lighten") ? lightness + 6.25 : lightness - 6.25;
        if (lightness > 100) {
            lightness = 100;
        }
        if (lightness < 0) {
            lightness = 0;
        }

        let RGB1 = hexToRGB(color),
            HSL = RGBtoHSL(RGB1[0], RGB1[1], RGB1[2]);
        let RGB = HSLToRGB(HSL[0], HSL[1], lightness),
            R = RGB[0],
            G = RGB[1],
            B = RGB[2];
        if (!(R >= 0) && !(R <= 255)) R = 0;
        if (!(G >= 0) && !(G <= 255)) G = 0;
        if (!(B >= 0) && !(B <= 255)) B = 0;
        R = (R >= 16) ? R.toString(16) : "0" + R.toString(16);
        G = (G >= 16) ? G.toString(16) : "0" + G.toString(16);
        B = (B >= 16) ? B.toString(16) : "0" + B.toString(16);
        R = (R.length === 1) ? R + R : R;
        G = (G.length === 1) ? G + G : G;
        B = (B.length === 1) ? B + B : B;

        return R + G + B;
    }

    return false;
}

export function RGBtoHSL(r, g, b) {
    let H,
        S,
        L,
        Min = 0,
        Max = 0;
    r = (parseInt(r) / 51) * .2;
    g = (parseInt(g) / 51) * .2;
    b = (parseInt(b) / 51) * .2;

    if (r >= g) {
        Max = r;
    } else {
        Max = g;
    }
    if (b > Max) {
        Max = b;
    }
    if (r <= g) {
        Min = r;
    } else {
        Min = g;
    }
    if (b < Min) {
        Min = b;
    }
    L = (Max + Min) / 2;
    if (Max === Min) {
        S = 0;
        H = 0;
    } else {
        if (L < .5) {
            S = (Max - Min) / (Max + Min);
        }
        if (L >= .5) {
            S = (Max - Min) / (2 - Max - Min);
        }
        if (r === Max) {
            H = (g - b) / (Max - Min);
        }
        if (g === Max) {
            H = 2 + ((b - r) / (Max - Min));
        }
        if (b === Max) {
            H = 4 + ((r - g) / (Max - Min));
        }
    }
    H = Math.round(H * 60);
    if (H < 0) H += 360;
    if (H >= 360) H -= 360;
    S = Math.round(S * 100);
    L = Math.round(L * 100);
    return [H, S, L];
}

export function HSLToRGB(H, S, L) {
    let p1, p2, R, G, B;
    L /= 100;
    S /= 100;
    if (L <= 0.5) p2 = L * (1 + S);
    else p2 = L + S - (L * S);
    p1 = 2 * L - p2;
    if (S === 0) {
        R = L;
        G = L;
        B = L;
    } else {
        R = FindRGB(p1, p2, H + 120);
        G = FindRGB(p1, p2, H);
        B = FindRGB(p1, p2, H - 120);
    }
    R *= 255;
    G *= 255;
    B *= 255;
    R = Math.round(R);
    G = Math.round(G);
    B = Math.round(B);

    return [R, G, B];
}

export function FindRGB(q1, q2, hue) {
    if (hue > 360) hue = hue - 360;
    if (hue < 0) hue = hue + 360;
    if (hue < 60) return (q1 + (q2 - q1) * hue / 60);
    else if (hue < 180) return (q2);
    else if (hue < 240) return (q1 + (q2 - q1) * (240 - hue) / 60);
    else return (q1);
}

export function lightenOrDarken(color, percent, rgb = false, raw = false) {
    // lighten or darken hex color by given percent (positive is lighten, negative is darken); returns HSL
    let R, G, B;

    color = color.replace("#", "");
    if (color.length === 3) {
        R = getRGB(color.substring(0, 1) + color.substring(0, 1));
        G = getRGB(color.substring(1, 2) + color.substring(1, 2));
        B = getRGB(color.substring(2, 3) + color.substring(2, 3));
    } else if (color.length === 6) {
        R = getRGB(color.substring(0, 2));
        G = getRGB(color.substring(2, 4));
        B = getRGB(color.substring(4, 6));
    }

    const [H,S,L] = RGBtoHSL(R, G, B);
    let lightness = L + (L * (percent * .01));
    if (lightness > 100) {
        lightness = 100;
    }
    if (lightness < 0) {
        lightness = 0;
    }

    if (rgb && raw) {
        return HSLToRGB(H,S,lightness);
    } else if (rgb) {
        [R,G,B] = HSLToRGB(H,S,lightness);
        return `rgb(${R},${G},${B})`;
    } else if (raw) {
        return [H,S,lightness];
    } else {
        const HSL = RGBtoHSL(R, G, B);
        return `hsl(${HSL[0]}, ${HSL[1]}%, ${lightness}%)`;
    }
}

export function hexToRGB(color) {
    color = color.replace("#", "");
    let R, G, B;
    if (color.length === 3) {
        R = getRGB(color.substring(0, 1) + color.substring(0, 1));
        G = getRGB(color.substring(1, 2) + color.substring(1, 2));
        B = getRGB(color.substring(2, 3) + color.substring(2, 3));
    } else if (color.length === 6) {
        R = getRGB(color.substring(0, 2));
        G = getRGB(color.substring(2, 4));
        B = getRGB(color.substring(4, 6));
    }
    return [R, G, B];
}

export function RGBToHex(r, g, b) {
    const rgbToHex = function (rgb) {
        let hex = Number(rgb).toString(16);
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex;
    };
    return '#' + rgbToHex(r) + rgbToHex(g) + rgbToHex(b);
}

export function getDarkerColor(hex1, hex2) {
    const lightness1 = color_lightness(hex1);
    const lightness2 = color_lightness(hex2);
    if (lightness1 < lightness2) {
        return hex1;
    }
    return hex2;
}

export function getFontColorRGB(hex) { // accepts a hex and returns an RGB
    if (checkContrast("4D4C40", hex)) {
        return "rgb(77,76,64)";
    }
    return "rgb(255,255,255)"; // should return rgb
}
