import URI from "urijs";

import {withLocaleInURI} from "../internationalization/i18nURI";
import JSONWebToken from "../utils/JSONWebToken";
import {isJson} from "../components/admin/spinup/utilities";



// Right now, this uses the $.ajax() call for XHRs.  As long as we are standardized on
// using this function and calling .done()/.fail() promise functions after, it should
// be easy enough to upgrade to NextJS SWR (fetch) tool in the future.
export function ConnectionFactory(orgId = '', url: string, settings: {
    [key: string]: any
} = {}): Promise<any> {
    // For settings information, see: https://api.jquery.com/Jquery.ajax/

    // the goal of this JWT is for per-org permission checking on the backend. it should ALWAYS reflect the
    // current organization ID. some routes may require their own permission checking (such as editing a
    // contact modal), but that's the concern of the backend. dumb frontend, smart backend.

    return new Promise(function(res, rej) {
        new JSONWebToken().encode(orgId).then(jwt => {
            const headers = {
                "api-key": jwt,
                "content-type": "application/x-www-form-urlencoded",
                "X-Requested-With": "XMLHttpRequest", // IMPORTANT so Yii evaluates isAjaxRequest to True
            }
            const requestOptions: RequestInit = {
                method: settings.method || "GET",
                // send api-key so we can set the correct organization in PHP off of the JWT.
                headers: settings.headers ? {...settings.headers, ...headers} : headers,
            };
            if (requestOptions.method === "GET" && settings.data) {
                // match jquery logic by appending data to uri if `data` is provided for a GET
                const uri = new URI(url);
                for (let k in settings.data) {
                    uri.addQuery(Array.isArray(settings.data[k]) ? `${k}[]` : k, settings.data[k]);
                }
                url = uri.normalize().toString();
            } else if (settings.data) {
                const formBody: string[] = [];
                for (let property in settings.data) {
                    const encodedKey = encodeURIComponent(property);
                    const encodedValue = encodeURIComponent(settings.data[property]);
                    formBody.push(encodedKey + "=" + encodedValue);
                }
                requestOptions.body = formBody.join("&");
            }

            fetch(url, requestOptions).then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => res(data));
                } else {
                    return response.text().then(text => res(isJson(text) ? JSON.parse(text) : text));
                }
            })
        })
    })
}

export function YiiUrlFactory(path: string, locale: string): string {
    if (!locale) {
        console.warn(`No locale set for URL ${path}`);
    }
    return withLocaleInURI(path, locale);
}

export class ApiController {
    static rootPrefix = "/_!_API_!_";
    static version = "2";

    static rootTypes = {
        ORGANIZATION: "organization",
        PERSON: "people",
        EVENTS: "events",
    };

    static fieldNames = {
        BLOCK: '_blockHTML',
        MODAL: '_modalHTML',
        AD: '_adHTML',
        GRID_STATES: '_gridStates',
        BLOCKOBJ: '_blockObj',
    };

    // This function is necessary because URIjs automatically encodes _!_ to _%21_.  Grrr.
    static fixEncoding(path: string): string {
        return path.replaceAll("_%21_", "_!_");
    }

    static viewOrganization(locale: string, orgId: string, blockId?: string, itemId?: string , fields?: string[]): string {
        let path = URI.joinPaths(this.rootPrefix, this.version, this.rootTypes.ORGANIZATION, orgId);
        if (blockId) {
            path = URI.joinPaths(path, 'blocks', blockId)

            if (itemId) {
                path = URI.joinPaths(path, 'items', itemId)
            }
        }

        if (fields) {
            path = path.search({fields: fields.join(",")})
        }
        return this.fixEncoding(YiiUrlFactory(path.toString(), locale));
    }
}

export class AuthenticationController {
    static prefix = "/cms/authentication";
    static rootPrefix = "/";

    static facebook(locale: string, jwt: string): string {
        const path = URI.joinPaths(this.prefix, "facebookLogin")
            .search({state: jwt})
            .toString();
        return YiiUrlFactory(path, locale);
    }

    static google(locale: string, jwt: string): string {
        const path = URI.joinPaths(this.prefix, "googleLogin")
            .search({state: jwt})
            .toString();
        return YiiUrlFactory(path, locale);
    }

    static login(locale: string): string {
        const path = URI.joinPaths(this.rootPrefix, "login").toString();
        return YiiUrlFactory(path, locale);
    }

    static logout(locale: string): string {
        const path = URI.joinPaths(this.rootPrefix, "logout").toString();
        return YiiUrlFactory(path, locale);
    }

    static office365(locale: string, jwt: string): string {
        const path = URI.joinPaths(this.prefix, "office365Login")
            .search({state: jwt})
            .toString();
        return YiiUrlFactory(path, locale);
    }

    static office365AdminSetupLogin(locale: string, jwt: string): string {
        const path = URI.joinPaths(this.prefix, "office365AdminSetupLogin")
            .search({state: jwt})
            .toString();
        return YiiUrlFactory(path, locale);
    }
}

export class CalendarController {
    static prefix = "/cms/calendar";

    static getCalendarsList(locale: string): string {
        const path = URI.joinPaths(this.prefix, "getcalendarslist").toString();
        return YiiUrlFactory(path, locale);
    }

    static getListing(locale: string): string {
        const path = URI.joinPaths(this.prefix, "getlisting").toString();
        return YiiUrlFactory(path, locale);
    }
}

export class DefaultController {
    static prefix = "/cms/default";

    static blockFetch(locale: string, blockId: string): string {
        const path = URI.joinPaths(this.prefix, "getAsyncBlock", blockId).toString();
        return YiiUrlFactory(path, locale);
    }

    static getEditBlock(locale: string, blockType: string): string {
        const path = URI.joinPaths(this.prefix, "getEditBlock", blockType, "edit").toString();
        return YiiUrlFactory(path, locale);
    }

    static blockCommand(locale: string, blockId: string): string {
        const path = URI.joinPaths(this.prefix, "blockCommand", blockId, "command").toString();
        return YiiUrlFactory(path, locale);
    }
}

export class PageController {
    static prefix = "/cms/page"

    static compare(locale: string, pageId: string, otherPageId: string): string {
        const path = URI.joinPaths(this.prefix, pageId, "compare", otherPageId).toString();
        return YiiUrlFactory(path, locale);
    }

    static image(locale: string): string {
        const path = URI.joinPaths(this.prefix, "saveImage").toString();
        return YiiUrlFactory(path, locale);
    }

    static update(locale: string, blockId: string): string {
        const path = URI.joinPaths(this.prefix, blockId, "update").toString();
        return YiiUrlFactory(path, locale);
    }

    static versions(locale: string, pageId: string): string {
        const path = URI.joinPaths(this.prefix, pageId, "version").toString();
        return YiiUrlFactory(path, locale);
    }

    static snippets(locale: string): string {
        const path = URI.joinPaths(this.prefix, "snippets").toString();
        return YiiUrlFactory(path, locale);
    }

    static restore(locale: string): string {
        const path = URI.joinPaths(this.prefix, "restore").toString();
        return YiiUrlFactory(path, locale);
    }
}

export class PartialsController {
    static prefix = "/partials"

    static googleFilePicker(locale: string): string {
        const path = URI.joinPaths(this.prefix, "googleFilePicker").toString();
        return YiiUrlFactory(path, locale);
    }
}

export class SearchController {
    static rootPrefix = "/";

    static search(locale: string): string {
        const path = URI.joinPaths(this.rootPrefix, "search").toString();
        return YiiUrlFactory(path, locale);
    }
}

export class StaffController {
    static prefix = "/cms/staff"

    static departments(locale: string, userId: string, action: ""|"create"|"delete"|"restore"|"update" = ""): string {
        const path = URI.joinPaths(this.prefix, userId, "departments", action).toString();
        return YiiUrlFactory(path, locale);
    }
}

export class UserController {
    static prefix = "/cms/user"

    static feed(locale: string): string {
        const path = URI.joinPaths(this.prefix, "feed").toString();
        return YiiUrlFactory(path, locale);
    }

    static following(locale: string): string {
        const path = URI.joinPaths(this.prefix, "following").toString();
        return YiiUrlFactory(path, locale);
    }

    static myCalendar(locale: string): string {
        const path = URI.joinPaths(this.prefix, "myCalendar").toString();
        return YiiUrlFactory(path, locale);
    }

    static settings(locale: string): string {
        const path = URI.joinPaths(this.prefix, "settings").toString();
        return YiiUrlFactory(path, locale);
    }
}
