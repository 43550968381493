import {SignJWT, decodeJwt} from "jose";
import { isSchoolBlocksApp, isSchoolFeedStandalone } from "./SchoolBlocksUtilities";

function getIssuer() {
    if (isSchoolBlocksApp()) {
        return "schoolblocks.com";
    }
    else if (isSchoolFeedStandalone()) {
        return "schoolfeed.org";
    } else {
        return "cleversite.com";
    }
}

const secret = new TextEncoder().encode('school-blocks-key');

export default class JSONWebToken {
    async encode(organizationId, url) {
        // Payload
        const payload = {
            iss: getIssuer(), // issuer
            aud: "schoolblocks.com", // audience
            orgid: organizationId,
            returnUrl: url,
        };

        return await new SignJWT(payload)
            .setProtectedHeader({ alg: "HS256" })
            .sign(secret);
    }

    decode(jwt) {
        return decodeJwt(jwt);
    }
}
