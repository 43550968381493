import BlockObject from "../BlockObject";
import {makeObservable, observable} from "mobx";
import {NormalizedVideoVendor} from "./NormalizedVideo";

export default class VideoBlockObject extends BlockObject implements IVideoBlockObj {
    embedResponsive?: boolean;
    isTitle?: boolean;
    link: string;
    modalId: number;
    video: IVideoObject;
    items: Array<IVideoObject>;
    videoType?: NormalizedVideoVendor;

    constructor(blockType: OrganizationType, initialBlockObj: IVideoBlockObj | undefined) {
        super(blockType, initialBlockObj);

        this.setAttributes(initialBlockObj);

        this.videoType = VideoBlockObject.getVideoType(initialBlockObj?.blockModel.json_data.block.settings.url || "");

        makeObservable(this, {
            embedResponsive: observable,
            isTitle: observable,
            link: observable,
            video: observable,
            videoType: observable,
        });
    }

    static getVideoType(url: string): NormalizedVideoVendor | undefined {
        if (/youtube\.com/.exec(url) ||
            /youtu\.be/.exec(url)
        ) {
            return NormalizedVideoVendor.YOUTUBE;
        }
        if (/vimeo\.com/.exec(url)) {
            return NormalizedVideoVendor.VIMEO;
        }
        if (/schooltube\.com/.exec(url)) {
            return NormalizedVideoVendor.SCHOOLTUBE;
        }
        if (/myvrspot\.com/.exec(url)) {
            return NormalizedVideoVendor.MYVRSPOT;
        }
        if (/drive\.google\.com/.exec(url) ||
            /docs\.google\.com/.exec(url)
        ) {
            return NormalizedVideoVendor.DRIVEGOOGLE;
        }
    }
}
