import BlockObject from "./BlockObject";
import {makeObservable, observable} from "mobx";

export default class OrganizationBlockObject extends BlockObject {

    constructor(blockType: OrganizationType, initialBlockObj: IBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            blockModel: observable,
        })
    }
}
