import Router from "next/router";
import URI from 'urijs';
import {isInternalNextLink} from "../../utils/SchoolBlocksUtilities";
import {interfaceStore} from "../../stores/StoreLoader";

class SafeRouter {
    event = typeof window !== 'undefined' && new CustomEvent('route');

    async push(url: string, as?: string, options?: any) {
        if (isInternalNextLink(url, interfaceStore.currentFullUrl || "")) {
            await Router.push(url, as, options);
        } else if (typeof window !== "undefined") {
            window.history.replaceState(null, document.title, url);
            // @ts-ignore
            window.dispatchEvent(this.event);
        }
    }

    uri() {
        // separate out so this can be more easily mocked
        return URI();
    }

    async setQuery(query: string, value?: string) {
        const uri = this.uri();
        let updated = false;
        if (value) {
            uri.setQuery(query, value);
            updated = true;
        } else if (uri.hasQuery(query)) {
            uri.removeQuery(query);
            updated = true;
        }

        if (updated) {
            const url = uri.toString();
            await this.push(url, url, {shallow: true});
        }
    }

    async setQueries(...queries: [string, string?][]) {
        const uri = this.uri();
        let updated = false;
        queries.forEach(q => {
            if (q[1]) { // if value is falsy, remove it
                uri.setQuery(q[0], q[1]);
                updated = true;
            } else if (uri.hasQuery(q[0])) {
                uri.removeQuery(q[0]);
                updated = true;
            }
        });

        if (updated) {
            const url = uri.toString();
            await this.push(url, url, {shallow: true});
        }
    }

    getUrlWithQueries(...queries: [string, string?][]) {
        const uri = this.uri();
        queries.forEach(q => {
            if (q[1]) { // if value is falsy, remove it
                uri.setQuery(q[0], q[1]);
            } else if (uri.hasQuery(q[0])) {
                uri.removeQuery(q[0]);
            }
        });
        return uri.toString();
    }
}

export default new SafeRouter();
