import {sourceFlaticons, sourceFontawesomeIcons, sourceTypes, sourceTitles, sourceTypesEnum} from "./SourceUtils";
import {regexMap} from "./Settings";

export const UTCDateFormat = "ddd MMM D HH:mm:SS ZZ YYYY";

export function regexQuote(str: string): string {
    return (str || '').replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
}

export function buildCssUrlString(url: string): string {
    return "url('" + encodeURI(url) + "')";
}

export function extractNumberFromPx(pxString: string): number {
    return parseInt(pxString, 10);
}

// unescape string that are escaped with CHtml::encode in yii
// see https://www.php.net/manual/en/function.htmlspecialchars.php for all translations
/** Used to map HTML entities to characters. */
const htmlUnescapes = new Map([
    ['&amp;', '&'],
    ['&lt;', '<'],
    ['&gt;', '>'],
    ['&quot;', '"'],
    ['&#39;', "'"], // from jquery.fancytree
    ['&#039;', "'"],
    ['&#x2F;', "/"], // from jquery.fancytree
]);

/** Used to match HTML entities and HTML characters. */
const reEscapedHtml = RegExp(Array.from(htmlUnescapes.keys()).join('|'), 'g');

export function unescapeHtml(str: any): string {
    if (typeof str !== 'string') return str;
    return reEscapedHtml.test(str) ?
        str.replace(reEscapedHtml, (entity) => (htmlUnescapes.get(entity) || entity))
        : str;
}
/**
 * This function takes an organization title like 'Ross Montessori School' and removes common words like school, district,
 * etc in order to return 'Ross Montessori'.  This is useful for making a title such as 'About Ross Montessori'
 * @param title string
 * @returns string
 */
export function makeOrganizationTitleReferencable(title: string): string {
    const wordsToRemove = [
        'school',
        'district',
        'llc',
        regexQuote('llc.'),
        'inc',
        regexQuote('inc.'),
    ];

    const re = new RegExp("\\b(?:"+ wordsToRemove.join("|") + ")\\b\\s*", "gi");
    return (title || '').replace(re, '').trim();
}

export function transformOrganizationType(type) {
    switch (type) {
        case 'sharedfiles':
            return 'Page';
        default:
            return capitalize(type);
    }
}

export function getSocialIcon(platform: string, fontawesome: boolean | undefined = false): string {
    const icon = fontawesome ? sourceFontawesomeIcons[platform] : sourceFlaticons[platform];
    return icon ? icon : '';
}

export function getBlockIcon(type: string): string | false {
    const icons = {
        "news": "flaticon-newspaper",
        "events": "flaticon-calendar-1",
        "gallery": "flaticon-image",
        "quicklinks": "flaticon-go-to-web",
        "map": "flaticon-map",
        "html": "flaticon-website",
        "reviews": "flaticon-star",
        "about": "flaticon-resume",
        "files_folders": 'flaticon-folder',
    };

    if (!type || !icons[type]) {
        return false;
    }

    return icons[type];
}

export function getPlatform(type: string): sourceTypesEnum {
    if (/drive/gi.test(type)) {
        return sourceTypesEnum.GOOGLE_DRIVE;
    }
    if (/classroom/gi.test(type)) {
        return sourceTypesEnum.GOOGLE_CLASSROOM;
    }
    for (let i = 0; i < sourceTypes.length; i++) {
        if (type && new RegExp(sourceTypes[i].type, 'gi').test(type)) {
            return sourceTypes[i].type;
        }
    }

    return sourceTypesEnum.ERROR;
}

export function getContentType(type: string): string | false {
    const allTypes = ['news', 'event', 'location', 'review', 'description'];

    for (let i = 0; i < allTypes.length; i++) {
        if (type && type.includes(allTypes[i])) {
            return allTypes[i];
        }
    }

    return false;
}

export function getSourceTitle(type: string): string {
    return typeof sourceTitles[type] === "string" ? sourceTitles[type] : 'error';
}

export const shortenTextBlock = (text: string, max: number, padding: number = 10): string => {
    if (text?.length > max) {
        let cutAt = text.indexOf(" ", max);
        if (cutAt > max + padding) { // make sure it's not just a super long link or something
            cutAt = max + padding;
        }
        return text.slice(0, cutAt) + "...";
    }
    return text;
};

export const capitalize = (word: string): string => {
    const wordArr = word.split(' ');
    let val = '';
    for (let i = 0; i < wordArr.length; i++) {
        val += `${wordArr[i].slice(0, 1).toUpperCase()}${wordArr[i].slice(1).toLowerCase()} `;
    }
    return val.trim();
};

export function hasHTML(value: string): boolean {
    return regexMap.html.test(value);
}

export function getCookieFromString(key, cookieString = "") {
    // this is mostly copied from the js-cookie library

    function read(value) {
        return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
    }

    const cookies = cookieString.split('; ')
    const jar = {}
    for (let i = 0; i < cookies.length; i++) {
        const parts = cookies[i].split('=')
        let value = parts.slice(1).join('=')

        if (value[0] === '"') {
            value = value.slice(1, -1)
        }

        try {
            const foundKey = read(parts[0]);
            jar[foundKey] =  read(value);

            if (key === foundKey) {
                break
            }
        } catch (e) {}
    }

    return key ? jar[key] : jar
}

export async function getValidCookieDomain() {
    const result = await fetch("/api/getValidCookieDomain");
    return await result.text();
}

export async function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + `; Domain=${await getValidCookieDomain()}; Path=/`;
}

export async function deleteCookie(cName) {
    document.cookie = `${cName}=deleted; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Domain=${await getValidCookieDomain()}; Path=/`
}
