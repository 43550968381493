import {observable, action, computed, toJS, makeObservable, values} from 'mobx';

import { OrFilter, OnlyOneFilter, StringFilter } from './GridFilters';
import {gridTypesEnum} from "../utils/SchoolBlocksUtilities";
import PersonBlockObject from "../components/blocks/SchoolBlocks/BlockObjects/PersonBlockObject";
import {blockObjectFactory} from "../components/blocks/SchoolBlocks/blockUtils";
import BlockObject from "../components/blocks/SchoolBlocks/BlockObjects/BlockObject";
import {ReactiveContentItem} from "./SchoolFeedStore";

class BlockVisibility {
    allBoolFilters = new Map();
    allMapFilters = new Map();

    constructor(id) {
        makeObservable(this, {
            setMapFilterValues: action,
            setBoolFilterValues: action,
            resetFilterValues: action,
            isVisible: computed,
            allBoolFilters: observable,
            allMapFilters: observable,
        });
        this.id = id;
    }

    setMapFilterValues(filterName, id, value) {
        if (!this.allMapFilters.has(filterName)) {
            this.allMapFilters.set(filterName, observable.map({}));
        }

        const filter = this.allMapFilters.get(filterName);
        filter.set(id, value);
    }

    setBoolFilterValues(filterName, value) {
        this.allBoolFilters.set(filterName, value);
    }

    resetFilterValues(filterName) {
        if (this.allMapFilters.has(filterName)) {
            const filter = this.allMapFilters.get(filterName);
            filter.clear();
        }

        if (this.allBoolFilters.has(filterName)) {
            this.allBoolFilters.set(filterName, true);
        }
    }

    get isVisible() {
        let visibleByFilters = true;

        this.allBoolFilters.forEach((isVisibleByFilter) => {
            visibleByFilters &= isVisibleByFilter;
        });

        let start,
            visibleByThisFilter;
        this.allMapFilters.forEach((filter) => {
            start = true;
            visibleByThisFilter = true;
            filter.forEach((isVisibleByFilter) => {
                if (start) {
                    start = false;
                    visibleByThisFilter = isVisibleByFilter;
                } else {
                    visibleByThisFilter |= isVisibleByFilter;
                }
            });
            visibleByFilters &= visibleByThisFilter;
        });

        return visibleByFilters;
    }
}

export default class GridStore {
    blocks = new Map();
    blockVisibility = new Map();
    id = null;
    mapEnabled = false;
    mapVisible = false;
    canEdit = true;
    gridType = null;

    constructor() {
        makeObservable(this, {
            blocks: observable,
            blockVisibility: observable,
            id: observable,
            mapEnabled: observable,
            mapVisible: observable,
            canEdit: observable,
            gridType: observable,
            setId: action,
            setBlocks: action,
            resizeBlock: action,
            relocateBlock: action,
            lockBlock: action,
            unlockBlock: action,
            deleteBlock: action,
            sortBlocksManually: action,
            setMapEnabled: action,
            toggleMapVisibility: action,
            getSortedBlocksByAlpha: action,
            loadedBlockIds: computed,
            visibleBlockIds: computed,
            totalVisible: computed,
            hasVisibleBlocks: computed,
            shouldShowMap: computed,
        });
        this.textFilter = new StringFilter(this);
        this.categoryFilters = new OrFilter(this, 'filterCategories');
        this.tagFilters = new OnlyOneFilter(this, 'filterTags');
        this.blockTypeFilters = new OrFilter(this, 'filterBlockType');
    }

    setId(id) {
        this.id = id;
    }

    setGridType(gridType) {
        this.gridType = gridType;
    }

    getSortedBlocksByAlpha(blocks) {
        if (this.gridType === gridTypesEnum.ALPHA_GRID) {
            return [...blocks].sort((a, b) => {
                const orderingStringA = a.blockType === 'person' ? a.blockModel.lname + a.blockModel.fname : a.filterString;
                const orderingStringB = b.blockType === 'person' ? b.blockModel.lname + b.blockModel.fname : b.filterString;
                return orderingStringA && orderingStringB ? orderingStringA.toUpperCase().localeCompare(orderingStringB.toUpperCase()) : 1;
            });
        }
        return blocks;
    }

    getBlockAsJs(blockId) {
        const block = this.blocks.get(blockId);
        if (block) {
            return toJS(block);
        }
        return null;
    }

    setBlocks(blocks, clear = false, sort = false) {
        if (clear) {
            this.blocks.clear();
            this.blockVisibility.clear();
        }
        blocks.forEach((block) => {
            if (block.hasOwnProperty("blockType") && !(block instanceof BlockObject)) {
                //@ts-ignore
                if (block.blockType === "person") {
                    //@ts-ignore
                    this.blocks.set(block.id, new PersonBlockObject(block.blockType, block));
                } else {
                    this.blocks.set(block.id, blockObjectFactory(block.blockType, block));
                }
            } else {
                // assume these are terraces
                if (block.type === "news") {
                    Array.from(Object.keys(block.content_data)).forEach(k => {
                        block.content_data[k].items.forEach((item, index, arr) => {
                            arr[index] = new ReactiveContentItem(item);
                        });
                    })
                } else if (block.content_items) {
                    block.content_items.forEach((item, index, arr) => {
                        arr[index] = new ReactiveContentItem(item);
                    });
                }
                this.blocks.set(block.id, block);
            }
            this.blockVisibility.set(block.id, new BlockVisibility(block.id));
        });
        if (sort) {
            // will sort all blocks in grid - should only be used when building a new grid or adding a new block
            this.sortBlocksManually(this.getSortedBlocksByAlpha(values(this.blocks)).map(b => b.id));
        }
    }

    resizeBlock(id, sizeX, sizeY) {
        const block = this.blocks.get(id);
        block.sizeX = sizeX;
        block.sizeY = sizeY;
    }

    relocateBlock(id, posX, posY) {
        const block = this.blocks.get(id);
        block.posX = posX;
        block.posY = posY;
    }

    lockBlock(id, posX, posY) {
        const block = this.blocks.get(id);
        block.positionLocked = true;
        this.relocateBlock(id, posX, posY);
    }

    unlockBlock(id) {
        const block = this.blocks.get(id);
        block.positionLocked = false;
    }

    deleteBlock(id) {
        this.blocks.delete(id);
        this.blockVisibility.delete(id);
    }

    sortBlocksManually(newOrder) {
        const newMap = new Map();
        let block;
        newOrder.forEach((id) => {
            block = this.blocks.get(id);
            if (block) {
                newMap.set(id, block);
            }
        });
        this.blocks.replace(newMap);
    }

    get sortedBlockState() {
        const state = [];
        let blockState;
        let i = 0;
        this.blocks.forEach((blockObj) => {
            blockState = {
                'id': blockObj.id,
                'sortOrder': i,
                'sizeX': blockObj.sizeX,
                'sizeY': blockObj.sizeY,
                'posX': 0,
                'posY': 0,
                'locked': blockObj.positionLocked, // 'locked' was the old key name.  we should start using positionLocked
                'positionLocked': blockObj.positionLocked,
            };
            if (blockState.locked) {
                // the posX and posY are only required when the block is locked.  Otherwise, saving this
                // blockState will make the Flux Capacitor not work right.  The block position is properly saved
                // on the lock/unlock action.
                blockState.posX = blockObj.posX;
                blockState.posY = blockObj.posY;
            }
            state.push(blockState);
            i++;
        });
        return state;
    }

    get loadedBlockIds() {
        return this.blocks.keys();
    }

    get visibleBlockIds() {
        const blockIds = [];
        this.blockVisibility.forEach((blockVis) => {
            if (blockVis.isVisible) {
                blockIds.push(blockVis.id);
            }
        });
        return blockIds;
    }

    get totalVisible() {
        return this.visibleBlockIds.length;
    }

    get hasVisibleBlocks() {
        return this.totalVisible !== 0;
    }

    get shouldShowMap() {
        return this.mapEnabled && this.mapVisible;
    }

    setMapEnabled(enabled) {
        this.mapEnabled = enabled;
    }

    toggleMapVisibility() {
        this.mapVisible = !this.mapVisible;
    }

    setCanEdit(val) {
        this.canEdit = val;
    }
}
