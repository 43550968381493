import {action, computed, makeAutoObservable, makeObservable, observable, runInAction} from "mobx";
import {interpret} from "xstate";
import {FetchMachine} from "../components/machines/FetchMachine";
import {getPlatform} from "../utils/StringUtilities";
import {sourceTypesEnum} from "../utils/SourceUtils";
import {isFacebookImageExpired} from "../utils/FacebookUtils";
import WatsonApi from "../backends/WatsonApi";
import {ExecutionEnvironment} from "./InterfaceStore";
import {getCdnUrl} from "../utils/SchoolBlocksUtilities";

export interface IReactiveContentItem extends ContentItem {
    update: (obj: { [v: string]: any }) => void,
    image: string,
}

export function isEvent(item: EventContentItem | ContentItem): item is EventContentItem {
    return (item as EventContentItem).is_event === true;
}

export function isMedia(item: MediaContentItem | ContentItem): item is MediaContentItem {
    return (item as MediaContentItem).is_media === true;
}

export function isFile(item: FileContentItem | ContentItem): item is FileContentItem {
    return (item as FileContentItem).is_file === true;
}

export function isNews(item: ContentItem): item is NewsContentItem {
    return (item).is_news === true;
}

export function isCoursework(item: CourseWorkContentItem | ContentItem): item is CourseWorkContentItem {
    return (item).is_coursework === true;
}

export type IReactiveNewsContentItem = IReactiveContentItem & (NewsContentItem | MediaContentItem | FileContentItem);
export type IReactiveEventContentItem = IReactiveContentItem & EventContentItem;
export type IReactiveFileContentItem = IReactiveContentItem & FileContentItem;
export type IReactiveFolderContentItem = IReactiveContentItem & FolderContentItem;
export type IReactiveMediaContentItem = IReactiveContentItem & MediaContentItem;
export type IReactiveCourseWorkContentItem = IReactiveContentItem & CourseWorkContentItem;
export type SchoolFeedContentItem = IReactiveEventContentItem |
                                    IReactiveNewsContentItem |
                                    IReactiveFileContentItem |
                                    IReactiveMediaContentItem |
                                    IReactiveCourseWorkContentItem;

export class ReactiveContentItem implements ContentItem {
    organization: OrganizationTypeOrganization;
    item: NewsContentItem | MediaContentItem | FileContentItem | EventContentItem | CourseWorkContentItem;
    id: number;

    public source;
    public third_party_id;
    public third_party_url;
    public type;
    public edited;
    public is_coursework;
    public is_event;
    public is_news;
    public is_media;
    public is_description;
    public is_location;
    public is_file;
    public is_folder;
    public is_review;
    public is_quicklink;
    public is_custom;
    public json_data;
    public created_at;
    public updated_at;
    public organization_id;
    public source_id;
    public published;
    public search_vector;
    public geo_point;
    public meritchat_enabled;
    public share_paths;

    constructor(item: NewsContentItem | MediaContentItem | FileContentItem | EventContentItem | CourseWorkContentItem) {
        this.item = item;

        const obj = {
            update: action,
            image: computed,
            link: computed,
            linkText: computed,
        }
        for (const key in item) {
            if (item.hasOwnProperty(key) && key !== "title" && key !== "description") {
                this[key] = item[key];
                obj[key] = observable;
            }
        }

        makeObservable(this, obj)
    }

    get image() {
        return getItemImage(this.item);
    }

    get altText() {
        return getAltText(this.item);
    }

    get title() {
        // Display the description as the title for Serp API. Why wouldn't we just populate the title field with the
        // contents of the description? Because we still need the value from title to use in the Read More on... button
        if (getPlatform(this.type) === sourceTypesEnum.SERP_API) {
            return this.item.description;
        }
        return this.item.title;
    }

    set title(title) {
        this.item.title = title;
    }

    get description() {
        if (getPlatform(this.type) === sourceTypesEnum.SERP_API) {
            return "";
        }
        return this.item.description;
    }

    set description(description) {
        this.item.description = description;
    }

    get link() {
        if (this.item.third_party_url) {
            return this.item.third_party_url;
        } else if (isNews(this.item) && this.item.json_data.news.topic_url) {
            return this.item.json_data.news.topic_url;
        } else {
            return undefined;
        }
    }

    get linkText() {
        if (this.item.third_party_url) {
            const attribution = getPlatform(this.item.type);
            let attributionTitle = "";
            if (this.item.source?.account_title !== undefined && attribution === sourceTypesEnum.RSS) {
                attributionTitle = this.item.source.account_title;
            } else if (attribution === sourceTypesEnum.SERP_API) {
                attributionTitle = this.item.title;
            } else {
                attributionTitle = attribution;
            }
            let linkTitle = ` on ${attributionTitle.toLowerCase()}`;
            return `View ${linkTitle}`
        } else if (isNews(this.item) && this.item.json_data.news.topic_url_text) {
            return this.item.json_data.news.topic_url_text;
        } else {
            return undefined;
        }
    }

    update(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                this[key] = obj[key];
                this.item[key] = obj[key]
            }
        }
    }
}

function isNotEmpty(item) {
    return item.title || item.image || item.description;
}

export function getAltText(item: ContentItem) {
    const isImage = /photo/.test(item.type);
    let text;

    if (isNews(item)) {
        text = item.json_data.news.cover_image_alt_text;
    } else if (isEvent(item)) {
        text = item.json_data.event.full_picture_alt_text;
    } else if (isMedia(item) && isImage && item.json_data.images && item.json_data.images.length > 0) {
        text = item.json_data.images[item.json_data.images.length - 1].alt_text;
    } else if (isMedia(item) && item.json_data.videos) {
        text = item.json_data.videos.videos_list[0].alt_text;
    } else if (isFile(item) && item.json_data.file.cover_image_url) {
        text = item.json_data.file.cover_image_alt_text;
    }

    if (!text) {
        if (item.is_custom) {
            text = item.title;
        } else {
            text = `Image from ${getPlatform(item.type)}`;
        }
    }

    return text;
}

function convertGoogleUrl(originalUrl: string) {
    const fileId = originalUrl.match(/\/uc\?id=(.*)/);
    if (fileId && fileId.length > 1) {
        return `https://drive.google.com/thumbnail?id=${fileId[1]}`;
    }
    return originalUrl;
}

/**
 * @param item -
 * @param useSourceImageDefault - default to item's content_source image if item doesn't have its own image
 */
export function getItemImage(item: ContentItem, useSourceImageDefault = true) {
    const isImage = /photo/.test(item.type);
    const platform = getPlatform(item.type);

    let image: string | null | undefined  = null;

    // first try to get the image from the content item json_data
    if (isNews(item) && item.json_data.news.cover_image_url) {
        image = item.json_data.news.cover_image_url;
    } else if (isEvent(item) && item.json_data.event && item.json_data.event.full_picture) {
        image = item.json_data.event.full_picture;
    } else if (isMedia(item) && isImage && item.json_data.images && item.json_data.images.length > 0) {
        image = item.json_data.images[item.json_data.images.length - 1].raw_url;
    } else if (isMedia(item) && item.json_data.videos) {
        const placeholder = require("../assets/images/placeholders/placeholder-video.png");
        image = item.json_data.videos.cover_image_url || placeholder;
    } else if (isFile(item) && item.json_data.file.cover_image_url) {
        image = item.json_data.file.cover_image_url;
    }
    // make sure image URL isn't expired if it's from Facebook - if it is, we need to continue onward
    if (platform === sourceTypesEnum.FACEBOOK && isFacebookImageExpired(image)) {
        image = null;
    }
    if (platform === sourceTypesEnum.GOOGLE_DRIVE && image) {
        image = convertGoogleUrl(image);
    }

    // if we still don't have an image, try to extract from content source, making sure that if it's from Facebook it isn't expired
    if (
        !image &&
        useSourceImageDefault &&
        item.source &&
        item.source.account_image &&
        !(
            platform === sourceTypesEnum.FACEBOOK &&
            isFacebookImageExpired(item.source.account_image)
        ) &&
        platform !== sourceTypesEnum.GOOGLE_DRIVE
    ) {
        if (platform === sourceTypesEnum.TWITTER) {
            // use larger version of profile picture
            image = item.source.account_image.replace("_normal", "");
        } else {
            image = item.source.account_image;
        }
    }

    if (item.is_custom || platform == sourceTypesEnum.SERP_API) {
        image = getCdnUrl(image || "");
    }

    return image;
}

/*
switched from managing content in React state to a mobx store primarily because needed a way to easily observe and react to
item property changes - i.e. the published value. React doesn't provide a straightforward way to do that since object references
do not change. Also added benefit of being able to manage fetching and content from a single class instance rather than using
multiple states that need to be combined inside the component.
*/
export class SchoolFeedStore {
    // news contains is_news, is_media, and is_file.
    allContent: Map<number, SchoolFeedContentItem> = new Map();
    fetchStatus = FetchMachine.initialState.value;

    service = interpret(FetchMachine).onTransition(current => {
        runInAction(() => this.fetchStatus = current.value);
    });

    constructor() {
        makeAutoObservable(this);

        if (ExecutionEnvironment.canUseDOM) {
            // this leaks memory, so very important this doesn't get run on the server!
            this.service.start();
        }
    }

    get news(): IReactiveNewsContentItem[] {
        return Array.from(this.allContent.values())
            .filter(c => c.is_news) as IReactiveNewsContentItem[];
    }

    get events(): IReactiveEventContentItem[] {
        return Array.from(this.allContent.values())
            .filter(c => c.is_event) as IReactiveEventContentItem[];
    }

    get files(): IReactiveFileContentItem[] {
        return Array.from(this.allContent.values())
            .filter(c => c.is_file || c.is_folder) as IReactiveFileContentItem[];
    }

    get media(): IReactiveMediaContentItem[] {
        return Array.from(this.allContent.values())
            .filter(c => c.is_media) as IReactiveMediaContentItem[];
    }

    get coursework(): IReactiveCourseWorkContentItem[] {
        return Array.from(this.allContent.values())
            .filter(c => c.is_coursework) as IReactiveCourseWorkContentItem[];
    }

    setContentItems = (items: ContentItem[], clear = false) => {
        if (clear) {
            this.allContent.clear();
        }
        items.forEach(item => {
            // @ts-ignore
            const contentItem: SchoolFeedContentItem = new ReactiveContentItem(item);
            this.allContent.set(contentItem.id, contentItem);
        });

    }

    deleteItem = async (item: SchoolFeedContentItem, organization_id: string): Promise<void> => {
        const client = await WatsonApi();
        await client.apis.organizations.organizations_content_items_delete({
            id: item.id,
            organization_pk: organization_id,
        });
        this.allContent.delete(item.id);
    }

    updateItem = (updatedItem: SchoolFeedContentItem): void => {
        this.allContent.set(updatedItem.id, updatedItem);
    }

    get isFetching(): boolean {
        return this.fetchStatus === "PENDING";
    }
}
