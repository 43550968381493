import {makeObservable, observable} from 'mobx';
import BlockObject from './BlockObject';

export default class EventBlockObject extends BlockObject implements IEventBlockObj {

    events: IEvent[];
    fullCalendarUrl: string;

    constructor(blockType: OrganizationType, initialBlockObj: IEventBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            events: observable,
            fullCalendarUrl: observable,
        });
    }

}
