import {makeObservable, observable} from "mobx";
import BlockObject from "./BlockObject";

export default class StaffBlockObject extends BlockObject implements IStaffBlockObj {

    displayStaffTitles: boolean | string;
    staffMembers: IUser[];

    constructor(blockType: OrganizationType, initialBlockObj: IStaffBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            displayStaffTitles: observable,
            staffMembers: observable,
        })
    }
}
