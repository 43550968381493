import {makeObservable, observable} from 'mobx';
import BlockObject from "./BlockObject";

export default class MessageBlockObject extends BlockObject implements IMessageBlockObj {

    colorCSS: string;
    httpHost: string;
    items: IMessageItem[];
    url: string;

    constructor(blockType: OrganizationType, initialBlockObj: IMessageBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            colorCSS: observable,
            httpHost: observable,
            items: observable,
            url: observable,
        })
    }
}
