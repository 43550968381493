import SafeRouter from "../../utilities/SafeRouter";
import {withLocaleInURI} from "../../../internationalization/i18nURI";

export function changeGoogleTranslate(langCode) {
    // needed for getting langCode of last async block event.
    if (langCode?.type === 'last-async-block') langCode = langCode.detail.langCode;

    function inner() {
        const firstValue = document.querySelector("select.goog-te-combo option:nth-child(1)");
        if (firstValue === null) {
            setTimeout(inner, 100);
        } else {
            if (langCode === 'en') langCode = 'en-US';
            const query = `select > option[value='${langCode}']`;
            const db = document.querySelector(query);
            if (langCode === 'en-US' && db === null) {
                const english = document.createElement('option');
                english.value = langCode;
                english.textContent = 'English';
                firstValue.parentNode?.insertBefore(english, firstValue.nextSibling);
                inner();
            } else {
                const trans = document.querySelector('select.goog-te-combo') as HTMLSelectElement;
                trans.value = langCode;
                const evt = new Event("change", {bubbles: true, cancelable: true} );
                return trans.dispatchEvent(evt);
            }
        }
    }

    inner();
}

export function i18nUrlHandler(locale: string): void {
    const currentUri = window.location.toString();
    const newUri = withLocaleInURI(currentUri, locale);

    console.debug(currentUri, newUri);
    if (newUri !== currentUri) {
        SafeRouter.push(newUri, newUri, {shallow: true, locale: false});
        if (!process.env.NEXT_PUBLIC_APP_ENV) {
            // remove once we get out of php - reloading because PHP builds the links based on routing/currentLocale.
            window.location.reload();
        }
    }
}
