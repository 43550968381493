import {observable, action, computed, makeObservable} from 'mobx';

export class OrFilter {
    gridStore = null;
    dataAttrName = null;
    filters = new Map();

    constructor(gridStore, dataAttrName) {
        makeObservable(this, {
            filters: observable,
            setFilters: action,
            allFiltersActive: computed,
            allTopFiltersActive: computed,
            allFiltersInactive: computed,
            allTopFiltersInactive: computed,
            allSubFiltersInactive: computed,
            makeAllFiltersActive: action,
            makeAllFiltersInactive: action,
            toggleFilter: action,
            resetBlockVisibility: action,
        });
        this.gridStore = gridStore;
        this.dataAttrName = dataAttrName;
    }

    setFilters(filters, topFilters = true, clear = false) {
        if (filters === undefined || filters === null || filters.length === 0) {
            return;
        }
        if (clear) this.filters.clear();

        filters.forEach((filter) => {
            filter.isTopFilter = topFilters;
            filter.active = topFilters; // in this case, we want active=true only if it is a topFilter

            const filterIds = [filter.id];

            if (filter.children) {
                const filterChildren = Object.values(filter.children);
                if (filterChildren.length > 0) {
                    this.setFilters(filterChildren, false);
                }

                // unfortunately we need to loop through the children to get all of the child ids
                // so we can add them to the regex.  I hate looping through the same set twice,
                // but there's no obvious real alternative right now.
                filterChildren.forEach((child) => {
                    filterIds.push(child.id);
                })
            }

            // This regex handles cases where any of the filter ids are the first item, the last item,
            // the only item, or in the middle of other category ids.
            filter.activeRegex = new RegExp('(?:^|[|])(?:' + filterIds.join("|") + ')(?:[|]|$)', "i");

            this.filters.set(filter.id, filter);
        });

        if (topFilters) {
            this.makeAllFiltersActive(true);
        }
    }

    resetBlockVisibility() {
        let blockVis;
        this.gridStore.blocks.forEach((block) => {
            blockVis = this.gridStore.blockVisibility.get(block.id);
            blockVis.resetFilterValues(this.dataAttrName);

            // if a block has no categories and we're using category filters, then we want to hide the block all the
            // time.
            if (block[this.dataAttrName] === undefined || block[this.dataAttrName] === null || block[this.dataAttrName] === "") {
                blockVis.setMapFilterValues(this.dataAttrName, 'null', false);
            }
        });
    }

    makeAllFiltersActive(topOnly = false) {
        this.filters.forEach((category) => {
            if (topOnly === false || category.isTopFilter) {
                category.active = true;
            }
        });

        this.resetBlockVisibility();
    }

    makeAllFiltersInactive(topOnly = false) {
        this.filters.forEach((category) => {
            if (topOnly === false || category.isTopFilter) {
                category.active = false;
            }
        });

        this.resetBlockVisibility();
    }

    get allFiltersActive() {
        let allActive = true;
        this.filters.forEach((filter) => {
            allActive &= filter.active;
        });

        return allActive;
    };

    get allTopFiltersActive() {
        let allActive = true;
        this.filters.forEach((filter) => {
            if (filter.isTopFilter) {
                allActive &= filter.active;
            }
        });

        return allActive;
    };

    get allFiltersInactive() {
        let allInactive = true;
        this.filters.forEach((filter) => {
            allInactive &= !filter.active;
        });

        return allInactive;
    };

    get allTopFiltersInactive() {
        let allInactive = true;
        this.filters.forEach((filter) => {
            if (filter.isTopFilter) {
                allInactive &= !filter.active;
            }
        });

        return allInactive;
    };

    get allSubFiltersInactive() {
        let allInactive = true;
        this.filters.forEach((filter) => {
            if (!filter.isTopFilter) {
                allInactive &= !filter.active;
            }
        });

        return allInactive;
    };



    toggleFilter(id) {
        const filter = this.filters.get(id);

        if (this.filters.size > 1 && this.allTopFiltersActive) {
            this.makeAllFiltersInactive(true);
        }

        filter.active = !filter.active;

        if (this.filters.size > 1
            && this.allSubFiltersInactive
            && ( this.allTopFiltersActive || this.allTopFiltersInactive)) {
            this.makeAllFiltersActive(true);
        } else {
            let blockVis,
                shouldShow;

            this.gridStore.blocks.forEach((block) => {
                blockVis = this.gridStore.blockVisibility.get(block.id);

                if (filter.active) {
                    shouldShow = filter.activeRegex.test(block[this.dataAttrName]);
                } else {
                    shouldShow = false;
                }

                // If the regex does match, then we DON'T want it hidden.
                // If the regex does not match, we DO want it hidden.
                blockVis.setMapFilterValues(this.dataAttrName, filter.id, shouldShow);
            });
        }
    }
}

export class OnlyOneFilter {
    gridStore = null;
    dataAttrName = null;
    filters = new Map();

    constructor(gridStore, dataAttrName) {
        makeObservable(this, {
            filters: observable,
            setFilters: action,
            toggleFilter: action,
        });
        this.gridStore = gridStore;
        this.dataAttrName = dataAttrName;
    }

    setFilters(filters, clear = false) {
        if (clear) this.filters.clear();
        if (filters === undefined || filters === null || filters.length === 0) {
            return;
        }

        filters.forEach((filter) => {
            filter.active = false;
            this.filters.set(filter.id, filter);
        });
    }

    toggleFilter(id) {
        this.filters.forEach((filter) => {
            if (filter.id === id) {
                filter.active = !filter.active;
            } else {
                filter.active = false;
            }
        });

        const filter = this.filters.get(id);

        // This regex handles cases where the filter id is the first item, the last item, the only item, or in the
        // middle of other category ids.
        const re = new RegExp('(^|[|])' + filter.id + '([|]|$)', "i");

        let blockVis,
            shouldShow;

        this.gridStore.blocks.forEach((block) => {
            blockVis = this.gridStore.blockVisibility.get(block.id);

            if (filter.active) {
                shouldShow = re.test(block[this.dataAttrName]);
            } else {
                shouldShow = true;
            }

            // If the regex does match, then we DON'T want it hidden.
            // If the regex does not match, we DO want it hidden.
            //blockVis.setMapFilterValues(this.dataAttrName, filter.id, shouldShow);
            blockVis.setBoolFilterValues(this.dataAttrName, shouldShow);
        });
    }
}

export class StringFilter {
    gridStore = null;
    dataAttrName = 'filterString';

    constructor(gridStore) {
        this.gridStore = gridStore;

        makeObservable(this, {
            filterByText: action,
        })
    }

    filterByText(value) {
        let blockVis;
        let re = null;

        if (value !== undefined && value !== null && value !== "") {
            re = new RegExp(value, "i");
        }

        this.gridStore.blocks.forEach((block) => {
            blockVis = this.gridStore.blockVisibility.get(block.id);
            if (re === null) {
                // If the search string is empty, then we DON'T want it hidden.
                blockVis.setBoolFilterValues(this.dataAttrName, true);
            } else {
                // If the regex does match, then we DON'T want it hidden.
                // If the regex does not match, we DO want it hidden.
                blockVis.setBoolFilterValues(this.dataAttrName, re.test(block[this.dataAttrName]));
            }
        });
    }
}
