import {observable, action, makeObservable} from 'mobx';

export default class ModalStore {
    constructor() {
        makeObservable(this, {
            stack: observable,
            addModal: action,
            clearAllModals: action,
            removeTop: action,
        });
    }

    stack = [];

    removeTop = () => {
        this.stack.pop();
    };

    addModal = (modalObj) => {
        this.stack.push(modalObj);
    };

    clearAllModals = () => {
        this.stack.length = 0;
    };
}

