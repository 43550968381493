import BlockObject from "./BlockObject";
import {makeObservable, observable} from "mobx";

export default class QuickLinkBlockObject extends BlockObject implements IQuicklinkBlockObj {

    links?: any;

    constructor(blockType: OrganizationType, initialBlockObj: IQuicklinkBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            links: observable,
        })
        this.title = (this.title ? this.title : "Quick Links");
    }
}
