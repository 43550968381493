import {Machine} from "xstate";

export const FetchMachine = Machine({
    id: 'data-fetch-machine',
    initial: 'IDLE',
    states: {
        IDLE: {
            type: 'atomic',
            on: {
                FETCH: 'PENDING',
            },
        },
        PENDING: {
            type: 'atomic',
            on: {
                FULFILL: 'SUCCESS',
                REJECT: 'FAILURE',
            },
        },
        SUCCESS: {
            type: 'atomic',
            on: {
                FETCH: 'PENDING',

            },
            after: {
                1000: 'IDLE',
            },
        },
        FAILURE: {
            type: 'atomic',
            on: {
                FETCH: 'PENDING',
            },
            after: {
                2000: 'IDLE',
            },
        },
    },
});
