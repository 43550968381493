import getNextConfig from 'next/config';

const buildtimeConfig: WebpackAppConfig = require("../_configs/configs") as WebpackAppConfig;

export function getConfig(): CommonAppConfig {
    const nextConfig: NextAppConfig | undefined = getNextConfig() as NextAppConfig | undefined;

    if (nextConfig !== undefined) {
        return nextConfig;
    }

    return buildtimeConfig;
}
