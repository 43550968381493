import {makeObservable, observable} from "mobx";
import BlockObject from "./BlockObject";

export default class PersonBlockObject extends BlockObject implements IPersonBlock {

    // @ts-ignore https://github.com/facebook/create-react-app/issues/8918
    blockModel: IUser;

    constructor(blockType: IBlockType, initialBlockObj: IPersonBlock | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            blockModel: observable,
        })
    }

    setAttributes(attrs): void {
        for (const key in attrs) {
            this[key] = attrs[key];
        }
    }
}
