import {makeObservable, observable} from 'mobx';
import BlockObject from './BlockObject';

export default class GroupBlockObject extends BlockObject implements IGroupBlockObj {
    groupUrl: string;
    subBlocks: Array<IGroupSubBlock> | { [p: string]: IGroupSubBlock };

    constructor(blockType: OrganizationType, initialBlockObj: IGroupBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            groupUrl: observable,
            subBlocks: observable,
        })
    }

}
