import {makeObservable, observable} from "mobx";
import BlockObject from "./BlockObject";

export default class PollBlockObject extends BlockObject implements IPollBlockObj {

    action: string;
    answers: [string];
    question: string;
    tid: string;
    userId: string;

    constructor(blockType: OrganizationType, initialBlockObj: IPollBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            action: observable,
            answers: observable,
            question: observable,
            tid: observable,
            userId: observable,
        })
    }
}
