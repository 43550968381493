import URI from 'urijs';

const { allLocaleStringsAsArray } = require("./SupportedLocales");

// case insensitive match of the application's supported locales
export const localeRegex = new RegExp("^(" + (allLocaleStringsAsArray.join("|") as string) + ")$", "i");

export function localeInURI(uri: string | undefined): string | null {
    if (!uri) return null;

    const parsedURI = URI(uri);
    // https://medialize.github.io/URI.js/docs.html#accessors-segment
    const localePathPart = parsedURI.segment(0);
    const locale = localeRegex.exec(localePathPart)
    return (locale ? locale[0] : null);
}

export function withLocaleInURI(uri: string, newLocale: undefined | string | null): string {
    // There might be cases where newLocale isn't set and we don't want to modify
    // the passed in URI with some unknown value.
    if (!uri || !newLocale) {
        return uri;
    }

    const parsedURI = URI(uri);
    if (localeInURI(uri)) {
        // Clear out the old locale
        parsedURI.segment(0, "");
    }

    let pathSegments = parsedURI.segment();
    if (pathSegments.length === 0 || pathSegments[0] === '') {
        pathSegments = [newLocale];
    } else {
        // Prepend the locale because one doesn't already exist
        pathSegments.unshift(newLocale);
    }
    parsedURI.segment(pathSegments);

    return parsedURI.normalize().toString();
}

export function withoutLocaleInURI(uri: string): string {
    try {
        const parsedURI = URI(uri);
        const localePathPart = parsedURI.segment(0);

        if (localePathPart) {
            const result = localeRegex.exec(localePathPart);

            if (result) {
                parsedURI.segment(0, "");
                return parsedURI.normalize().toString();
            }
        }
    } catch (e) {
        console.error(e);
    }

    return uri;
}
