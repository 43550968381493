import BlockObject from "./BlockObject";

export default class SchoolFeedFullPageBlockObject extends BlockObject {

    constructor(blockType: IBlockType, initialBlockObj: IBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        this.changeDimensions(initialBlockObj);
    }
    changeDimensions(initialBlockObj) {
        if (initialBlockObj.json_data?.news?.cover_image_url || initialBlockObj.json_data?.file?.cover_image_url) {
            this.sizeX = 2;
        }
    }
}
