import WatsonApi from "../../../backends/WatsonApi";

export type ContentSourceState = {
    description: string,
    id: number,
    state: string,
    source_state: string,
    transition: string,
    timestamp: string,
}

export const poll = async (promiseFn, returnFn, cbFn, catchFn) => {
    // accepts "promiseFn" which is primary promise we are initiating and want to poll the results of
    // returnFn is what we want to do with the data that is returned from each step
    // cbFn (callback) is run when state is finished

    let step = 0;
    const currentStateObject: Array<ContentSourceState> = [];
    let currentState;

    const loopFn = async () => {

        try {
            const res: {
                data: Array<ContentSourceState>,
                links: {
                    next: null | string,
                },
                meta: {
                    total: number,
                }
            } = await promiseFn();

            // update currentStateObject with any new results
            for (let i = currentStateObject.length; i < res.data.length; i++) {
                currentStateObject.push(res.data[i]);
            }

            currentState = currentStateObject[step];
            if (currentState && (currentState.state === 'finished' || currentState.state === 'error_fetching_items')) {
                return cbFn(currentStateObject[step]);
            } else {
                setTimeout(async () => {
                    // it's very possible we create a race condition here, so we should locally save the state object to be sure
                    // we always have a complete set of data
                    if (currentStateObject.length > step + 1) {
                        step++;
                    }

                    returnFn(currentStateObject[step]);
                    await loopFn();
                }, 1000);
            }
        }
        catch (error) {
            catchFn(error);
        }
    };
    await loopFn()
        .catch(catchFn)
};

export const pollOrganization = organization_id => {
    return new Promise((res, rej) => {
        WatsonApi().then(client => {
            client.apis.organizations.organizations_states_list({
                organization_pk: organization_id,
            }).then(obj => {
                res(JSON.parse(obj.data));
            }).catch(error => {
                console.log('Oops! ', error);
            })
        }).catch(error => {
            console.log('Oops! ', error);
        })
    });
};

export const pollSource = (organization_id, content_id) => {
    return new Promise((res, rej) => {
        WatsonApi().then(client => {
            client.apis['content-sources'].content_sources_states_list({
                contentsource_pk: content_id,
            }).then(obj => {
                res(JSON.parse(obj.data));
            }).catch(error => {
                console.log('Oops! ', error);
            })
        }).catch(error => {
            console.log('Oops! ', error);
        })
    });
};

export function isJson(str): str is string {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
