import {makeObservable, observable} from 'mobx';
import BlockObject from "./BlockObject";

export default class InstagramBlockObject extends BlockObject implements IInstagramBlockObj {

    constructor(blockType: OrganizationType, initialBlockObj: IInstagramBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
    }
}
