import App from 'next/app';
import {withRouter} from 'next/router';

import getAllStores from "../utils/useAllStores";
import {StoreContext} from "../stores/StoreLoader";
import {withLocaleInURI} from "../internationalization/i18nURI";
import {IView} from "../stores/InterfaceStore";

// This is a COMMON NextJS App class, shared between CleverSite and SchoolBlocks for consistency.
export default withRouter(class SoprisApps extends App {
    allStores: any = {};

    constructor(props: SoprisAppsProps) {
        super(props);

        this.allStores = getAllStores();
        this.updateApp(props);
    }

    // We're still using the default return value for shouldComponentUpdate in a React.Component class,
    // but we're going to loop into the nextProps variable and make sure that currentFullUrl and allStores are updated
    // before the render call is made.
    shouldComponentUpdate(nextProps) {
        this.updateApp(nextProps);
        return true;
    }

    updateApp(theProps: SoprisAppsProps) {
        const {pageProps, router} = theProps;
        const {pageProps: prevPageProps} = this.props;

        // We need to set the currentFullUrl on the client side now in order to respond to shallow routes (when
        // getServerSideProps isn't called).  This is a good thing.
        // The logic around what locale we're putting in the URL might not be right though.  We need to double check
        // the value that NextJS is using in router.local with what we should be setting/using in i18nStore.
        pageProps.currentFullUrl = withLocaleInURI(
            pageProps.currentBaseUrl ? pageProps.currentBaseUrl + router.asPath : router.asPath,
            router.locale
        );
        // Let's always prefer the next/router locale instead of whatever may or may not have been returned from
        // getServerSideProps().  This also ensures that shallow route changes in the language tool work properly.
        pageProps.locale = router.locale;

        this.allStores.i18nStore.setLocaleByUrl(this.allStores.i18nStore.getLocaleRecord(router.locale).locale);
        this.allStores.interfaceStore.setCurrentFullUrl(pageProps.currentFullUrl);
        this.allStores.interfaceStore.closeAllMenus();
        this.allStores.interfaceStore.setUserAgent(pageProps.userAgent);
        this.allStores.interfaceStore.setView(pageProps.view);

        if (pageProps.organization &&
            pageProps.organization.id !== this.allStores.organizationStore.organization.id) {
            this.allStores.organizationStore.buildOrganization(pageProps.organization);
            this.allStores.styleStore.updateStyles(pageProps.organization.json_data?.settings, pageProps.currentOrganization, this.allStores.interfaceStore);
            this.allStores.i18nStore.setOrgDefaultLocale(pageProps.organization.json_data?.settings?.languages?.sourceLanguage);
            this.allStores.i18nStore.setOrganizationSupportedLocales(pageProps.organization.json_data?.settings?.languages?.targetLanguages);
        }

        const orgIdHasChanged = pageProps.currentOrganization &&
            pageProps.currentOrganization.id !== this.allStores.organizationStore.currentOrganization.id

        // perform these actions on page transitions and initial load, but not on query parameter changes
        if (orgIdHasChanged || (prevPageProps ? prevPageProps.view !== pageProps.view : true)) {
            if (pageProps.currentOrganization && pageProps.currentOrganization.user_permissions) {
                this.allStores.userStore.setPermissions(pageProps.currentOrganization.user_permissions);
            }
            this.allStores.modalStore.clearAllModals(); // clear modals so we remove any open modals before routing
            // sometimes we need data from an updated currentOrganization even when only the view has changed (i.e. schoolfeed)
            this.allStores.organizationStore.setCurrentOrganization(pageProps.currentOrganization);
            if (this.allStores.interfaceStore.schoolFeedWidgetOpen) this.allStores.interfaceStore.toggleSchoolFeedWidgetOpen();

            if (pageProps.blocks) {
                this.allStores.gridStore.setBlocks(pageProps.blocks, true, pageProps.view === IView.ALPHA);
            }
        }

        /*
        When there is a locale in the URL, we can run into the situation where the i18nStore is instantiated, meaning
        googleCode is computed, but this method (updateApp) has not yet run. So when language logic is first run, there
        is no locale defined by URL which can result in issues. This ensures that we don't run any language logic until
        after we know we have the full context of the page.
         */
        this.allStores.i18nStore.setContextIsUpdated(true);
    }

    render() {
        const {Component, pageProps} = this.props;

        console.debug('_app render page: ', pageProps.currentFullUrl);

        return <StoreContext.Provider value={this.allStores}>
            <Component {...pageProps} />
        </StoreContext.Provider>
    }
});
