import variables from "../styles/_variables.module.scss"
import {extractNumberFromPx} from "./StringUtilities";

const {
    universalDefaultLocale,
} = require("../internationalization/SupportedLocales");

export const primaryNavHeight = extractNumberFromPx(variables["navbar-container-height"]);
export const mobileNavWidth = 1200;

export const regexMap = {
    hashtags: /(^|\B)#(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/g,
    links: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\S*)?/mi,
    tags: /(^|\B)@(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/g,
    html: /<(?:.|\n)*?>/gm,
    mdLink: /\[.*\]\((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\S*)?\)/mi,
    email: /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gmi
};

const getDefaultColorByVertical = vertical => {
    switch (vertical) {
        case "coffee_shop":
            return "#4d2912";
        case "education":
            return "#357a92";
        default:
            return "#6C819B";
    }
};

export const defaultFontFamily = '"Open Sans", sans-serif';

export const defaultJsonData = vertical => {
    return {
        settings: {
            appearance: {
                fontFamily: defaultFontFamily,
                source: "default",
                primaryNavBackground: "#3772d9",
                secondaryNavBackground: "#043385",
                fontColor: "#000000",
                blocks: getDefaultColorByVertical(vertical),
                elements: "#103b73",
                themeID: "1",
                themes: {
                    "1": {
                        primaryNavBackground: "#FFFFFF",
                        secondaryNavBackground: "#333C4E",
                        blocks: "#333C4E",
                        elements: "#333C4E",
                    },
                    "2": {
                        primaryNavBackground: "#3772d9",
                        secondaryNavBackground: getDefaultColorByVertical(vertical),
                        blocks: getDefaultColorByVertical(vertical),
                        fontFamily: defaultFontFamily,
                    },
                    "3": {
                        primaryNavBackground: "#3772d9",
                        primaryNavFontColor: "#346887",
                        blocks: "#0d3e7a",
                        elements: "#103b73",
                    },
                },
                hideHeaderTitle: false,
                hideLoginButton: false,
                showWelcomePage: false,
                showStaffDirectoryEmailAddresses: true,
                showSchoolFeedWidget: "off",
                inheritSchoolFeedWidget: true,
            },
            about: {
                media_url: "",
                embed_type: "youtube",
            },
            aboutText: "",
            alert: {
                "fontColor": "#222222",
                "backgroundColor": "#dddddd",
            },
            htmlBlocks: {
                "html-0": {
                    "html": "",
                    "title": "HTML Block",
                },
            },
            footerText: "",
            block_config: new Map([
                ["hero", true],
                ["files_folders", true],
                ["about", false],
                ["events", true],
                ["reviews", true],
                ["news", true],
                ["gallery", true],
                ["map", true],
                ["quicklinks", true],
                ["html-0", false],
            ]),
            hero: [],
            calendar: {
                defaultView: null,
            },
            languages: {
                sourceLanguage: universalDefaultLocale,
                targetLanguages: [],
            },
            webapp: {
                enabled: false,
                icon: null,
            },
            identity: {
                provider: null,
                google: {
                    domain: "",
                    adminAccount: null,
                    groupMappings: {},
                    searchDrive: true,
                    userFieldsToOverrideAndSync: {
                        title: false,
                        phone: false,
                    },
                },
                office365: {
                    domain: "",
                    groupMappings: {},
                    searchDrive: false,
                    userFieldsToOverrideAndSync: {
                        title: false,
                        phone: false,
                        photo: false,
                    },
                },
            },
            analytics: {
                google_analytics_id: null,
            },
        },
    }
};
