const localeRegistry = require("./LocaleRegistry.json");

// This file uses CJS syntax instead of ESM because it is used in next.config.js
// with require() instead of import

// use <language>-<region> pattern (BCP 47-style)
// https://www.unicode.org/reports/tr35/tr35-59/tr35.html#Identifiers

// Language and region tag registry:
// https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
// https://icu4c-demos.unicode.org/icu-bin/locexp

// Language Names in native language
// https://www.internationalphoneticalphabet.org/languages/language-names-in-native-language/

const universalDefaultLocale = 'en-US';
const supportedLocales = new Map();

// These are all languages that Google supports.
const unsupportedLocalesString = 'af,sq,am,ar,hy,az,eu,be,bn,bs,bg,ca,ceb,zh,zh-CN,co,hr,cs,da,nl,eo,et,fi,fr,fy,gl,ka,' +
    'de,el,gu,ht,ha,haw,iw,hi,hmn,hu,is,ig,id,ga,it,ja,jv,kn,kk,km,rw,ko,ku,ky,lo,la,lv,lt,lb,mk,mg,ms,ml,mt,' +
    'mi,mr,mn,my,ne,no,ny,or,ps,fa,pl,pt,pa,ro,ru,sm,gd,sr,st,sn,sd,si,sk,sl,so,es,su,sw,sv,tl,tg,ta,tt,te,th,' +
    'tr,tk,uk,ur,ug,uz,vi,cy,xh,yi,yo,zu';
const unsupportedLocales = unsupportedLocalesString.split(',');

// NextJS will complain if our system-wide default locale isn't in the "locales"
// configuration object *exactly* the same way (case sensitive).  To work within that
// constraint, we'll create two Map objects: one that is case sensitive and one that is
// insensitive.  Then we'll build Array objects from them and NextJS can use the
// case sensitive one.  When we're searching for the primary locale based on something
// passed in the URL, we can use the case insensitive one.  Whew.
const allLocaleStrings = new Map();
const allLocaleStringsLowerCase = new Map();

let entry, variants;
Object.keys(localeRegistry).forEach((key) => {
    entry = localeRegistry[key];
    supportedLocales.set(key, entry);

    // Saving these keys as lowercase allows us to easily do case-insensitive lookups by key.
    // The NextJS application does case-insensitive comparisons already, so this is just for
    // our own code.
    variants = [entry.locale, ...entry.acceptedLocales];
    variants.forEach((variant) => {
        allLocaleStrings.set(variant, entry.locale);
        allLocaleStringsLowerCase.set(variant.toLowerCase(), entry.locale);

        // Make sure we can accept both variants like "en-us" and "en_us"
        if (variant.search("-")) {
            variant = variant.replace('-', '_');
            allLocaleStrings.set(variant, entry.locale);
            allLocaleStringsLowerCase.set(variant.toLowerCase(), entry.locale);
        } else if (variant.search("_")) {
            variant = variant.replace('_', '-');
            allLocaleStrings.set(variant, entry.locale);
            allLocaleStringsLowerCase.set(variant.toLowerCase(), entry.locale);
        }
    });
});

const allLocaleStringsAsArray = Array.from(allLocaleStrings.keys());
const allLocaleStringsLowerCaseAsArray = Array.from(allLocaleStringsLowerCase.keys());

module.exports = {
    allLocaleStrings,
    allLocaleStringsAsArray,
    allLocaleStringsLowerCase,
    allLocaleStringsLowerCaseAsArray,
    localeRegistry,
    supportedLocales,
    universalDefaultLocale,
    unsupportedLocales,
    unsupportedLocalesString,
};
