export const TitleOrganizationTypes = [
    "organization",
    "district",
    "school",
] as const;

export const OrganizationTypesWithGrids = [
    'activity',
    'classroom',
    'collection',
    'department',
    'district',
    'group',
    'organization',
    'root',
    'section',
    'school',
    'team',
] as const;

export const OrganizationTypesWithTerracedGrids = ["school", "district", "department"];

export const OrganizationTypesWithStaffBlock = [
    'department',
    'group',
    'team',
    'section',
] as const;

export const OrganizationTypesWithSubNavs = [
    "classroom",
    "section",
    "group",
    "department",
    "team",
    "activity"
]