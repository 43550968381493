import * as MobxReact from "mobx-react";
import allStoresSingleton, {loadStores} from "../../components/stores/StoreLoader";
import {ExecutionEnvironment} from '../../components/stores/InterfaceStore';

export default function getAllStores() {
    let allStores;
    if (ExecutionEnvironment.canUseDOM) {
        MobxReact.enableStaticRendering(false);
        // we don't want to create a new set of stores every time we call this function, and we can't use the singleton
        // on the server because the singleton is global across all instances of the server/all requests
        allStores = allStoresSingleton;
    } else {
        MobxReact.enableStaticRendering(true);
        allStores = loadStores();
    }

    return allStores;
}