import {makeObservable, observable} from 'mobx';
import BlockObject from './BlockObject';

export default class SharedFilesBlockObject extends BlockObject implements ISharedFilesBlockObj {
    fileBlockType: "normalizedFile" | "normalizedFolder" | "onedrive" | "linkToExternalItem" | "file";
    gFileId?: string;
    file?: {
        embeddedViewLink?: string,
        icon: string,
        type: string,
        viewerType: string,
        viewLink: string,
    };
    fileType: "audio" | "image" | "googledocs" | "video" | null;
    fileOrFolder?: {
        icon: string,
        viewLink: string,
        type: string,
    };
    folder?: {
        files: any,
        icon: string,
        title: string,
        type: string,
        viewLink: string,
    };
    url?: string;

    constructor(blockType: OrganizationType, initialBlockObj: ISharedFilesBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            url: observable,
        });
    }

    contentItemToSettings(contentItem) {
        return {
            url: contentItem.third_party_url || '',
        }
    }

    settingsObjectToSettings(settingsObj: IBlockSettingsObject) {
        return {
            url: settingsObj.url,
            source_id: settingsObj.source_id,
        }
    }


}
