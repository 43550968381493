import {action, autorun, makeAutoObservable, reaction} from "mobx";

import { ExecutionEnvironment } from './InterfaceStore';
import { isSchoolBlocksApp } from "../utils/SchoolBlocksUtilities";
import { changeGoogleTranslate, i18nUrlHandler } from '../components/header/LanguageTool/languageToolUtils';
import {getCookieFromString} from "../utils/StringUtilities";

const {
    allLocaleStringsAsArray,
    allLocaleStringsLowerCase,
    localeRegistry,
    supportedLocales,
    universalDefaultLocale,
    unsupportedLocales,
} = require("../internationalization/SupportedLocales");

/*
 * I18nStore Observables:
 *   localeSetByUrl:               // observable - the one in the URL, set by NextJS router object
 *   localeSetByUser:              // observable - the one the user selected
 *   organizationSupportedLocales: // observable - the list of primary locale names, configured by the organization
 *   applicationSupportedLocales:  // observable - the list NextJS is configured with, from LocaleRegistry
 *   orgDefaultLocale:             // observable - the locale the Organization has selected as their default
 *
 * Actions:
 *   setLocaleByUrl:  this is how we set the localeSetByUrl observable
 *   setLocaleByUser: this is how we set the localeSetByUser observable
 *
 * Computed:
 *   currentOrgSupportedLocale:    // computed - the current, best locale that is supported by the organization
 *     - Used to render URLs and <HEAD> tags for SEO
 *   currentAppSupportedLocale:    // computed - the current, best locale that is supported by the application
 *     - Used by facebookCode to display better Facebook content if we know the code, not just if its school supported
 */

export default class i18nStore {
    isSchoolBlocks = false;
    localeSetByUrl: string | null = null;
    localeSetByUser: string | null = null;
    organizationSupportedLocales: string[] = [universalDefaultLocale as string];
    applicationSupportedLocales: string[] = [universalDefaultLocale as string];
    blockEditLanguage: string = universalDefaultLocale;
    orgDefaultLocale: string = universalDefaultLocale as string;
    contextIsUpdated: boolean = false;

    constructor(organizationStore) {
        makeAutoObservable(this,{
            setLocaleByUrl: action,
            setLocaleByUser: action,
            getLocaleRecord: false,
            orgDefaultLocale: false,
        });

        // setup a reaction only on client to avoid memory leaks
        if (ExecutionEnvironment.canUseDOM) {

            reaction(
                () => organizationStore.organization?.json_data?.settings?.languages?.targetLanguages,
                this.setOrganizationSupportedLocales
            )
            reaction(
                () => organizationStore.organization?.json_data?.settings?.languages?.sourceLanguage,
                this.setOrgDefaultLocale
            )
        }

        this.isSchoolBlocks = isSchoolBlocksApp();
        if (ExecutionEnvironment.canUseDOM) {

            autorun(
                () => {
                    if (this.shouldChangeGoogleTranslate && this.contextIsUpdated) {
                        changeGoogleTranslate(this.googleCode)
                    }
                }
            )
            if (this.shouldChangeGoogleTranslate) {
                window.addEventListener("last-async-block", changeGoogleTranslate);
            }


            reaction(
                () => this.currentOrgSupportedLocale,
                (newOrgSupportedLocale) => {
                    i18nUrlHandler(newOrgSupportedLocale);
                }
            )
        }
    }

    setContextIsUpdated = (value: boolean): void => {
        this.contextIsUpdated = value;
    }

    setOrgDefaultLocale = (locale: string | undefined): void => {
        if (locale !== undefined && this.orgDefaultLocale !== locale) {
            this.orgDefaultLocale = locale;
        }
    }

    setOrganizationSupportedLocales = (organizationSupportedLocalesObject: string[] | undefined | null): void => {
        if (organizationSupportedLocalesObject !== undefined && organizationSupportedLocalesObject !== null) {
            this.organizationSupportedLocales = [...new Set(Array.from(organizationSupportedLocalesObject).concat([universalDefaultLocale as string]))];
        }
    }

    setBlockEditLanguage(locale: string | null): void {
        this.blockEditLanguage = locale || universalDefaultLocale;
    }

    setLocaleByUser(locale: string | null): void {
        this.localeSetByUser = locale;
    }

    setLocaleByUrl(locale: string | null): void {
        this.localeSetByUrl = locale;
    }

    getLocaleRecord(locale: string): LocaleRegistryRecord | undefined {
        const key = allLocaleStringsLowerCase.get(locale.toLowerCase()) as string | undefined;
        return supportedLocales.get(key) as LocaleRegistryRecord | undefined;
    }

    get currentOrgSupportedLocale(): string {
        // This computed value uses the same logic as the currentAppSupportedLocale but adds one more check
        // to see if the locale is listed as an organization supported locale.  If it's not, then we go back
        // to the universal default locale key.
        if (this.organizationSupportedLocales.includes(this.currentAppSupportedLocale)) {
            return this.currentAppSupportedLocale;
        }

        return universalDefaultLocale as string;
    }

    get currentAppSupportedLocale(): string {
        let record: LocaleRegistryRecord | undefined;
        if (this.localeSetByUser) {
            // If the user has selected a language, prefer that first
            record = this.getLocaleRecord(this.localeSetByUser);
        } else if (this.localeSetByUrl) {
            // Else, if the language is set in the URL, prefer that second
            record = this.getLocaleRecord(this.localeSetByUrl);
        } else {
            // default is the org's default locale
            record = this.getLocaleRecord(this.orgDefaultLocale);
        }

        if (record) {
            // If one of the above located a record in our registry, then it is "Application Supported" and
            // we return the locale key.  This ensures we are returning the "official", proper locale key.
            // For example, if the locale set by the URL is es-MX, we should return es-XL because that's our
            // offical local key for Spanish (es-MX is listed as a variant in our registry).
            return record.locale;
        }

        // Last resort: return the universal default locale key.
        return universalDefaultLocale as string;
    }

    getLanguage(locale: string): string {
        return supportedLocales.get(locale).language as string;
    }

    inRegistry(locale: string): boolean {
        return allLocaleStringsAsArray.includes(locale) as boolean;
    }

    get appSupportedButNotorganizationSupportedLocales(): Array<string> {
        const modLoc = Object.keys(localeRegistry);
        this.organizationSupportedLocales.forEach((loc) => {
            const index = modLoc.indexOf(loc);
            if (index !== -1) {
                modLoc.splice(index, 1);
            }
        })
        return modLoc;
    }

    get currentLanguageString(): string {
        const currentLocale = this.localeSetByUser || this.currentAppSupportedLocale;
        if (this.inRegistry(currentLocale)) {
            return this.getLanguage(currentLocale);
        } else {
            const query = `select > option[value='${currentLocale}']`;
            return document.querySelector(query)?.textContent || "";
        }
    }

    get facebookCode(): string {
        const currentAppSupportedLocaleRecord = this.getLocaleRecord(this.currentAppSupportedLocale);
        if (currentAppSupportedLocaleRecord) {
            return currentAppSupportedLocaleRecord.thirdPartyApis.facebook;
        }

        return "en_US";
    }

    // this is a 2-letter code used by google, youtube, schooltube, etc.
    get googleCode(): string {
        let localeRecord: LocaleRegistryRecord | undefined;
        if (this.localeSetByUser) {
            localeRecord = this.getLocaleRecord(this.localeSetByUser);
            if (!localeRecord) {
                // this is an unsupported application language, so just pass it back to Google Translator to handle
                return this.localeSetByUser;
            }
        } else if (this.localeSetByUrl) {
            localeRecord = this.getLocaleRecord(this.localeSetByUrl);
        }
        else {
            // default is the org's default locale
            localeRecord = this.getLocaleRecord(this.orgDefaultLocale);
        }
        if (localeRecord) {
            return localeRecord.langCode;
        }

        return "en";
    }

    get languageSetByGoogle() {
        let cookie = null;
        if (typeof getCookieFromString('googtrans',document.cookie) !== "undefined") {
            const cookieParts = getCookieFromString('googtrans',document.cookie).split("/")
            if (cookieParts.length > 1){
                cookie = cookieParts[2];
            }
            else {
                cookie = cookieParts[0]
            }
            if (cookie === "en-US") {
                return "en";
            }
        }
        return cookie;
    }

    get shouldChangeGoogleTranslate() {
        // Google Translate has a known bug of causing duplication text when translating a page, this shows up in our
        // message blocks quite often. These rules should eliminate this happening on sites using their default language
        // the bug will still appear when translating the site (including back to the default language but a refresh
        // should fix this). Therefore, we only want to call translation features when we are sure it is for sure needed
        // This is when a user selects a new language from the dropdown, adds/changes/removes a locale to the URL.

        // extract language section of the locale from the URL and the default locale
        const languageSetByUrl = this.localeSetByUrl?.split("-")[0] || null;
        const orgDefaultLanguage = this.orgDefaultLocale?.split("-")[0];
        const languageSetByUser = this.localeSetByUser?.split("-")[0] || null;

        return (this.languageSetByGoogle !== languageSetByUrl && languageSetByUrl !== null) || // URL locale doesn't match the locale google is using, so we need to translate
            (this.languageSetByGoogle !== languageSetByUser && languageSetByUser !== null) || // Language set by user doesn't match language google is using, so we need to translate
            (languageSetByUrl === null && this.languageSetByGoogle !== orgDefaultLanguage); // there is no URL locale, and google's locale isn't the default
    }

    // this returns an array of 2-letter language codes of all Google languages
    // but also with all our app supported locales removed, for generating unsupported dropdown items.
    get unsupportedLocales(): Array<string> {
        const uLoc = unsupportedLocales;
        supportedLocales.forEach((loc) => {
            const index = uLoc.indexOf(loc.langCode);
            if (index !== -1) {
                uLoc.splice(index, 1);
            }
        });
        return uLoc;
    }
}
