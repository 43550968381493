import {observable, action, toJS, autorun, computed, makeObservable} from 'mobx';

import {ExecutionEnvironment} from './InterfaceStore';
import {deleteCookie, getCookieFromString} from "../utils/StringUtilities";
import {getCdnUrl} from "../utils/SchoolBlocksUtilities";

const _localStoragekey = "userStore";

export default class UserStore {
    id = null;
    username = null;
    firstName = null;
    lastName = null;
    _avatar = null;
    oauthDomain = null;
    oauthId = null;
    stripe_customer = null;
    admin = false;
    viewer = false;
    editor = false;
    follower = false;

    supportChatKey = null;
    supportChatOpen = false;

    updateStripeCustomer = customer => this.stripe_customer = customer;

    constructor() {
        makeObservable(this, {
            id: observable,
            username: observable,
            firstName: observable,
            lastName: observable,
            _avatar: observable,
            oauthDomain: observable,
            oauthId: observable,
            stripe_customer: observable,
            admin: observable,
            viewer: observable,
            editor: observable,
            follower: observable,
            loadFromClientStorage: action,
            updateUser: action,
            setPermissions: action,
            logout: action,
            updateStripeCustomer: action,
            isAdmin: computed,
            isEditor: computed,

            supportChatEnabled: computed,
            supportChatKey: observable,
            supportChatOpen: observable,
            openSupportChat: action,
            closeSupportChat: action,
        });
        if (ExecutionEnvironment.canUseDOM && window.localStorage) {
            this.loadFromClientStorage();
            autorun(() => {
                // This code will run every time any observable property on the store is updated.
                const mobxJson = JSON.stringify(toJS(this));
                localStorage.setItem(_localStoragekey, mobxJson);
            });
        }
    }

    loadFromClientStorage() {
        if (ExecutionEnvironment.canUseDOM && window.localStorage) {
            const mobxJson = localStorage.getItem(_localStoragekey);
            // presence of a valid token is required for authenticated API calls to succeed
            // should only load user if token is present
            const token = getCookieFromString("token", document.cookie);
            if (mobxJson && token) {
                const user = JSON.parse(mobxJson);
                this.id = user.id;
                this.username = user.username;
                this.firstName = user.firstName;
                this.lastName = user.lastName;
                this._avatar = user.avatar;
                this.oauthDomain = user.oauthDomain;
                this.oauthId = user.oauthId;
                this.stripe_customer = user.stripe_customer;
                this.admin = user.admin;
                this.editor = user.editor;
                this.viewer = user.viewer;
                this.follower = user.follower;
                this.supportChatKey = user.supportChatKey;
                this.supportChatOpen = user.supportChatOpen || false;
            }
        }
    }

    get isAdmin() {
        return this.admin || false;
    }

    get isEditor() {
        return this.editor || false;
    }

    get avatar() {
        return getCdnUrl(this._avatar);
    }

    openSupportChat() {
        this.supportChatOpen = true;
    }

    closeSupportChat() {
        this.supportChatOpen = false;
    }

    get supportChatEnabled() {
        return this.isAdmin || this.isEditor;
    }

    updateUser(user) {
        user = user || {};

        if (user.hasOwnProperty('id')) {
            this.id = user.id;
        }

        if (user.hasOwnProperty('username')) {
            this.username = user.username;
        }

        if (user.hasOwnProperty('fname')) {
            this.firstName = user.fname;
        }

        if (user.hasOwnProperty('lname')) {
            this.lastName = user.lname;
        }

        if (user.hasOwnProperty('avatar')) {
            this._avatar = user.avatar;
        }

        if (user.hasOwnProperty('oauthDomain')) {
            this.oauthDomain = user.oauthDomain;
        }

        if (user.hasOwnProperty('oauthId')) {
            this.oauthId = user.oauthId;
        }

        if (user.hasOwnProperty('stripe_customer')) {
            this.stripe_customer = user.stripe_customer;
        }

        if (user.hasOwnProperty('supportChatKey')) {
            this.supportChatKey = user.supportChatKey;
        }
    }

    setPermissions = permissions => {
        // loop through because permissions object may not contain all permission levels
        for (let k in permissions) {
            if (permissions.hasOwnProperty(k)) {
                this[k] = permissions[k];
            }
        }
    };

    logout = async () => {
        this.id = null;
        this.username = null;
        this.firstName = null;
        this.lastName = null;
        this._avatar = null;
        this.oauthDomain = null;
        this.oauthId = null;
        this.stripe_customer = null;
        this.admin = false;
        this.editor = false;
        this.follower = false;
        this.supportChatKey = null;
        this.supportChatOpen = false;
        await deleteCookie("token");
        await deleteCookie("context")
    }
}

