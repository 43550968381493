import {makeObservable, observable} from 'mobx';
import BlockObject from "./BlockObject";

export default class PinterestBlockObject extends BlockObject implements IPinterestBlockObj {

    embedType: string;
    pinterestUrl: string;

    constructor(blockType: OrganizationType, initialBlockObj: IPinterestBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            embedType: observable,
            pinterestUrl: observable,
        })
    }
}
