import {makeObservable, observable} from "mobx";
import BlockObject from "./BlockObject";

export default class NewsBlockObject extends BlockObject implements INewsBlockObj {

    constructor(blockType: OrganizationType, initialBlockObj: INewsBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            subBlocks: observable,
            organizationUrl: observable,
            organizationId: observable,
            displayAsContent: observable,
        })
    }

    displayAsContent: boolean;
    organizationId: string;
    organizationUrl: string;
    subBlocks: INewsSubBlock[];

}
