import BlockObject from "./BlockObject";

export default class FacebookBlockObject extends BlockObject implements IFacebookBlockObj {

    constructor(blockType: OrganizationType, initialBlockObj: IBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
    }

}
