import {makeObservable, observable} from "mobx";
import BlockObject from "./BlockObject";

export default class WeatherBlockObject extends BlockObject implements IWeatherBlockObj {

    commandUrl: string;
    location: string;
    randomID: string;

    constructor(blockType: OrganizationType, initialBlockObj: IWeatherBlockObj | undefined) {
        super(blockType, initialBlockObj);
        this.setAttributes(initialBlockObj);
        makeObservable(this, {
            commandUrl: observable,
            location: observable,
            randomID: observable,
        })
    }
}
